import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import product_Action from "../../Redux/Products/action";
import cartActions from "../../Redux/cart/actions";
import commonActions from "../../Redux/common/actions";
import userActions from "../../Redux/user/actions";
import { IoIosArrowForward } from "react-icons/io";
import {
  MdOutlineCurrencyRupee,
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { DecryptFunction, EncryptFunction } from "../../utils/cryptoFunction";
import { priceRoundOff } from "../../utils/validation";
import { LogEvent } from "../../utils/firebase";
import noImg from "../../assets/images/noImage.jpg";
import { IoIosArrowBack } from "react-icons/io";

const Product = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { FarmEquipments } = useSelector(
    (state) => state.FarmEquipmentsReducer
  );
  const { ProductList } = useSelector((state) => state.ProductListReducer);
  // console.log("check-1",ProductList)
  const { userAddress } = useSelector((state) => state.userReducer);
  const { currentLanguage } = useSelector((state) => state.commonReducer);
  const { homeMarketplaceCattle } = useSelector((state) => state.AuthReducer);
  const [isHovered, setIsHovered] = useState(false);

  const filteredFarmEquipments = ProductList?.data?.filter(
    (product) => product.cattle_type.toLowerCase() === "supplement"
  );
  // console.log("filteredFarmEquipments",filteredFarmEquipments)
  useEffect(() => {
    dispatch({ type: product_Action.GET_PRODUCTS_LIST });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token") && localStorage.getItem("auth_user")) {
      const decryptedId = DecryptFunction(localStorage.getItem("auth_user"));
      if (decryptedId) {
        dispatch({
          type: userActions.GET_USER_ADDRESS,
          payload: { id: decryptedId },
        });
      }
    }
    //eslint-disable-next-line
  }, []);

  const [activeTab, setActiveTab] = useState("herbal");

  const convertToKilograms = (grams) => {
    if (grams >= 1000) {
      return (grams / 1000).toFixed(2) + "kg";
    } else {
      return grams + "gm";
    }
  };

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
    prevArrow: <MdOutlineArrowBackIos />,
    nextArrow: <MdOutlineArrowForwardIos />,
  };

  const handleAddToCart = (e, value) => {
    e.stopPropagation();
    LogEvent("Home_BestSeller_Products_AddToCart");
    let discountValue = 0;
    if (value?.discount_status === 1 && value.discount_amount > 0) {
      if (value?.discount_type === 1) {
        discountValue = (value?.amount * value?.discount_amount) / 100;
      } else {
        discountValue = value?.discount_amount;
      }
    }
    dispatch({
      type: cartActions.CURRENT_ADDING_CART_PRODUCT,
      payload: {
        order_items: [
          {
            product_id: value?.id,
            rate: value?.amount,
            quantity: 1,
            name: value?.language?.find(
              (lang) => lang?.language_code === currentLanguage
            )?.medicine_name,
            discount: priceRoundOff(discountValue),
            zoho_item_id: 0,
            gst: value?.gst,
            coupon_discount: 0,
          },
        ],
      },
    });
    if (localStorage.getItem("token")) {
      if (userAddress?.length === 0) {
        sessionStorage.setItem("addtocart", "Address");
        dispatch({
          type: commonActions.SET_SHOW_USER_ADDRESS_MODAL,
          payload: true,
        });
      } else {
        dispatch({ type: cartActions.ADD_PRODUCT_TO_CART });
      }
    } else {
      sessionStorage.setItem("addtocart", "Login");
      dispatch({ type: commonActions.SET_SHOW_LOGIN_MODAL, payload: true });
    }
  };

  const handleViewMore = () => {
    LogEvent("Home_BestSeller_ViewAll");
    navigate("/products?category=herbal");
  };

  const handleViewDetails = (e, id, value) => {
    e.preventDefault();
    LogEvent("Home_BestSeller_Products");
    const encrypt = EncryptFunction(id);
    if (encrypt) {
      navigate(`/product-details?id=${encrypt}`, { state: value });
    }
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <IoIosArrowBack size={24} />
      </div>
    );
  };

  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <IoIosArrowForward size={24} />
      </div>
    );
  };

  

  return (
    <div
      className="container-fluid farm-equipments no-border"
      style={{ backgroundColor: "#F6F7FF" }}
    >
      <div className="container">
        <div className="d-flex flex-row justify-content-between align-items-center mt-3">
          {/* Left Side - Navigation Tabs */}

          <div className="nav nav-tabs custom-nav-tabs">
            <div className="nav-item">
              <button
                className={`nav-link ${activeTab === "herbal" ? "active" : ""}`}
                onClick={() => handleTabClick("herbal")}
              >
                {t("Herbal Medicines")}
              </button>
            </div>
            <div className="nav-item">
              <button
                className={`nav-link ${activeTab === "feed" ? "active" : ""}`}
                onClick={() => handleTabClick("feed")}
              >
                {t("Feed Supplements")}
              </button>
            </div>
          </div>

          {/* Right Side - See More Button */}
          <div
            className="view-more-button"
            onClick={handleViewMore}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {t("View More")}
            <svg
              className="view-more-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M10 17l5-5-5-5v10z" />
            </svg>
          </div>
        </div>

        <div className="tab-content mt-5">
          {activeTab === "herbal" && (
            <Slider
              {...settings}
              className="slider-arrow-custom-style-for-related-products"
            >
              {ProductList?.data
                ?.filter(
                  (product) =>
                    product.cattle_type === "cattle" ||
                    product.cattle_type === "Calf"
                )
                .map((product, i) => (
                  <div key={i} className="relate_product_bg_color_div_home">
                    <div
                      className="product-details-related-product-img-div cursor-pointer"
                      onClick={(e) =>
                        handleViewDetails(e, product?.id, product)
                      }
                    >
                      <img
                        src={product?.image_url}
                        className="product-details-related-product-img"
                        alt="..."
                      />
                    </div>
                    <div className="related_product_card_body">
                      <div
                        className="related-product-name cursor-pointer"
                        onClick={(e) =>
                          handleViewDetails(e, product?.id, product)
                        }
                      >
                        {product?.language?.find(
                          (lang) => lang?.language_code === currentLanguage
                        )?.medicine_name || product?.medicine_name}
                      </div>
                      <div
                        className="related_product_pack_weight_div cursor-pointer description-container"
                        onClick={(e) =>
                          handleViewDetails(e, product?.id, product)
                        }
                      >
                        {product?.language?.find(
                          (lang) => lang?.language_code === currentLanguage
                        )?.discription || product?.discription}
                      </div>

                      <div
                        className="cursor-pointer"
                        onClick={(e) =>
                          handleViewDetails(e, product?.id, product)
                        }
                      >
                        {product?.discount_status === 1 ? (
                          <div>
                            <div className="related_product_card_price_Del">
                              <div>{t("MRP")}</div>
                              <del>
                                <MdOutlineCurrencyRupee />
                                {product.amount}
                              </del>
                              {product.discount_amount > 0 && (
                                <span className="related_product_dis_value">
                                  <>
                                    {product?.discount_type === 1
                                      ? `${product.discount_amount}% off`
                                      : `- ₹${product.discount_amount}`}
                                  </>
                                </span>
                              )}
                            </div>
                            {product?.discount_type === 1 ? (
                              <span className="related-product-final-price-span mt-2">
                                {" "}
                                <MdOutlineCurrencyRupee />
                                {priceRoundOff(
                                  product?.amount -
                                    (product?.amount *
                                      product?.discount_amount) /
                                      100
                                )}
                              </span>
                            ) : (
                              <span className="related-product-final-price-span mt-2">
                                <MdOutlineCurrencyRupee />
                                {priceRoundOff(
                                  product?.amount - product?.discount_amount
                                )}
                              </span>
                            )}
                          </div>
                        ) : (
                          <>
                            <div className="related-product-final-price-span d-flex justify-content-between">
                              <div className="price-container d-flex">
                                <span>₹{product?.amount}</span>
                              </div>
                              <Button
                                className="relatedproduct-viewproduct-btn_home"
                                onClick={(e) => handleAddToCart(e, product)}
                              >
                                {t("Buy Now")}
                              </Button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              <div onClick={handleViewMore}>
                <div className="container farm-equipment-view-all">
                  <h5 className="farm-equipment-view-all-btn">
                    {t("View All")} <IoIosArrowForward />
                  </h5>
                </div>
              </div>
            </Slider>
          )}
          {activeTab === "feed" && (
            <Slider
              {...settings}
              arrows
              prevArrow={<PrevArrow />}
              nextArrow={<NextArrow />}
            >
              {ProductList?.data
                ?.filter((product) => product.cattle_type === "supplement")
                .map((product, i) => (
                  <div key={i} className="relate_product_bg_color_div_home">
                    <div
                      className="product-details-related-product-img-div cursor-pointer"
                      onClick={(e) =>
                        handleViewDetails(e, product?.id, product)
                      }
                    >
                      <img
                        src={product?.image_url}
                        className="product-details-related-product-img"
                        alt="..."
                      />
                    </div>
                    <div className="related_product_card_body">
                      <div
                        className="related-product-name cursor-pointer"
                        onClick={(e) =>
                          handleViewDetails(e, product?.id, product)
                        }
                      >
                        {product?.language?.find(
                          (lang) => lang?.language_code === currentLanguage
                        )?.medicine_name || product?.medicine_name}
                      </div>
                      <div
                        className="related_product_pack_weight_div cursor-pointer description-container"
                        onClick={(e) =>
                          handleViewDetails(e, product?.id, product)
                        }
                      >
                        {product?.language?.find(
                          (lang) => lang?.language_code === currentLanguage
                        )?.discription || product?.discription}
                      </div>

                      <div
                        className="cursor-pointer"
                        onClick={(e) =>
                          handleViewDetails(e, product?.id, product)
                        }
                      >
                        {product?.discount_status === 1 ? (
                          <div>
                            <div className="related_product_card_price_Del">
                              <div>{t("MRP")}</div>
                              <del>
                                <MdOutlineCurrencyRupee />
                                {product.amount}
                              </del>
                              {product.discount_amount > 0 && (
                                <span className="related_product_dis_value">
                                  <>
                                    {product?.discount_type === 1
                                      ? `${product.discount_amount}% off`
                                      : `- ₹${product.discount_amount}`}
                                  </>
                                </span>
                              )}
                            </div>
                            {product?.discount_type === 1 ? (
                              <span className="related-product-final-price-span mt-2">
                                {" "}
                                <MdOutlineCurrencyRupee />
                                {priceRoundOff(
                                  product?.amount -
                                    (product?.amount *
                                      product?.discount_amount) /
                                      100
                                )}
                              </span>
                            ) : (
                              <span className="related-product-final-price-span mt-2">
                                <MdOutlineCurrencyRupee />
                                {priceRoundOff(
                                  product?.amount - product?.discount_amount
                                )}
                              </span>
                            )}
                          </div>
                        ) : (
                          <>
                            <div className="related-product-final-price-span d-flex justify-content-between">
                              <div className="price-container d-flex">
                                <span>₹{product?.amount}</span>
                              </div>
                              <Button
                                className="relatedproduct-viewproduct-btn_home"
                                onClick={(e) => handleAddToCart(e, product)}
                              >
                                {t("Buy Now")}
                                {/* {t('Add To Cart')} */}
                              </Button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              <div onClick={handleViewMore}>
                <div className="container farm-equipment-view-all">
                  <h5 className="farm-equipment-view-all-btn">
                    {t("View All")} <IoIosArrowForward />
                  </h5>
                </div>
              </div>
            </Slider>
          )}
          <div className="text-center mt-4"></div>
        </div>
      </div>
    </div>
  );
};

export default Product;

// import React, { useEffect, useState } from "react";
// import { Button, Col, Container, Row } from "react-bootstrap";
// import { useTranslation } from 'react-i18next';
// import { useNavigate, useSearchParams } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import cartActions from "../../Redux/cart/actions";
// import { EncryptFunction } from "../../utils/cryptoFunction";
// import { PiTrash } from "react-icons/pi";
// import { MdOutlineCurrencyRupee } from "react-icons/md";
// import { LogEvent } from "../../utils/firebase";

// export default function CartIndex() {

//     const { t } = useTranslation();

//     const navigate = useNavigate();
//     const dispatch = useDispatch();
// const [searchParams] = useSearchParams();
// const id = searchParams.get('id');

//     const { cartProducts } = useSelector(state => state.cartReducer);
// const { currentLanguage } = useSelector(state => state.commonReducer);

// console.log('Product ',cartProducts)

//     const [priceDetails, setPriceDetails] = useState({
//         amount: 0,
//         discount: 0,
//         total: 0
//     });

//     useEffect(() => {
//         if (id) {
//             dispatch({ type: cartActions.GET_CART_PRODUCT_LIST, payload: { id: id } });
//         } else if (localStorage.getItem('token') && localStorage.getItem('auth_user')) {
//             navigate(`/cart?id=${localStorage.getItem('auth_user')}`);
//             dispatch({
//                 type: cartActions.GET_CART_PRODUCT_LIST,
//                 payload: { id: localStorage.getItem('auth_user') }
//             });
//         };
//     }, [id]);

//     useEffect(() => {
//         if (cartProducts && cartProducts?.order_items?.length > 0) {
//             let array = { amount: 0, discount: 0, total: 0 }
//             cartProducts?.order_items?.map((val) => {
//                 let product_rate = parseFloat(val?.rate) * parseInt(val?.quantity)
//                 array['amount'] = parseFloat(array['amount']) + parseFloat(product_rate);
//                 let product_dis = parseFloat(val?.discount) * parseInt(val?.quantity)
//                 array['discount'] = parseFloat(array['discount']) + parseFloat(product_dis);
//             });
//             array['total'] = array['amount'] - array['discount'];
//             setPriceDetails(array);
//         };
//     }, [cartProducts]);

//     const handleRemoveProductFromCart = (value) => {
//         LogEvent('Cart_Product_Remove_Clicked');
//         dispatch({
//             type: cartActions.REMOVE_PRODUCT_FROM_CART,
//             payload: { id: value?.id, order_id: value?.order_id, user_id: id }
//         });
//         window.location.reload();
//     };

//     const handleCheckout = (e) => {
//         e.preventDefault();
//         LogEvent('Cart_Continue_Clicked')
//         navigate(`/checkout?id=${localStorage.getItem('auth_user') ?? id}`);
//     };

//     const handleQtyChange = (e, value) => {
//         console.log(value)
//         dispatch({
//             type: cartActions.CURRENT_ADDING_CART_PRODUCT,
//             payload: {
//                 // data:{
//                     "order_items": [
//                         {
//                             "product_id": value?.id,
//                             "rate": value?.rate,
//                             "quantity": e.target.value,
//                             "name": value?.language?.find(lang => lang?.language_code === currentLanguage)?.medicine_name,
//                             // "name":value.name,
//                             "discount": value?.discount,
//                             "zoho_item_id": 0,
//                             "gst": value?.gst,
//                             "coupon_discount": 0
//                         }
//                     ]
//                 },
//                 // cattle:"market"
//                 // ]
//             // }
//         });
//         dispatch({ type: cartActions.ADD_PRODUCT_TO_CART, payloadData: { type: 'qty_update' } });
//     }

//     const handleViewProductDetails = (id) => {
//         LogEvent('Cart_Product_Clicked')
//         const encrypt = EncryptFunction(id);
//         if (encrypt) {
//             navigate(`/product-details?id=${encrypt}`);
//         }
//     }

//     return (
//         <Container className="my-4">
//             <Row className="row-padding-remover">
//                 {(cartProducts?.length === 0 || cartProducts?.order_items?.length === 0) ?
//                     <Col md={12} className="d-flex justify-content-center">
//                         <div className="text-center">
//                             <img src={require('../../assets/images/emptycart.png')} alt="" className="empty-cart-img" />
//                             <div className="cart-noproducts-text">{t("No Items Found in Cart")}</div>
//                             <a href="/products?category=herbal" className="btn btn-primary">Add items to cart</a>
//                         </div>
//                     </Col> :
//                     <>
//                         <Col lg={8} md={12} className="cart-page-product-list-col">
//                             {cartProducts?.order_items?.map((val, i) =>
//                                 <div className="cart-page-product-image-name-div cursor-pointer" key={i} >
//                                     <div className="card-product-details-div" onClick={() => handleViewProductDetails(val?.product_id)}>
//                                         <img
//                                             src={val?.image_url}
//                                             className="cart-product-img"
//                                             alt="..."
//                                         />
//                                         <div className="cart_product_details_content">
//                                             <div className="cart-product-name">{val?.name}</div>
//                                             <div className="cart-product-weight-cls">{val?.package_weight_in_gram} gm</div>
//                                             <div className="product_page_price_main_div_cls">
//                                                 {(val?.discount > 0) ?
//                                                     <>
//                                                         <span className="product-list-view-product-amount-del-tag me-1"><MdOutlineCurrencyRupee /><del>{parseFloat(val?.rate)}</del></span>
//                                                         <span className="product-final-price ms-1"> <MdOutlineCurrencyRupee />{parseFloat(val?.rate) - parseFloat(val?.discount)}/-</span>
//                                                     </> :
//                                                     <span className="product-final-price"><MdOutlineCurrencyRupee />{parseFloat(val?.rate)}/-</span>
//                                                 }
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="cart-product-qty-maind-cls">
//                                         <div className="cart-produtcs-qty-label">{t("Quantity")} :</div>
//                                         <select className="cart-product-select-qty-tag" onChange={(e) => handleQtyChange(e, val)} defaultValue={val?.quantity}>

//                                             {Array.from({ length: val.product.max_qty - val.product.min_qty + 1 }, (_, index) => val.product.min_qty + index).map((qty, ind) =>
//                                                 <option defaultValue={qty} key={ind}>{qty}</option>
//                                             )}
//                                         </select>
//                                     </div>
//                                     <div className="cart_product_remove_btn" onClick={() => handleRemoveProductFromCart(val)}>

//                                         <PiTrash size={18} /> {t("Remove")}
//                                     </div>
//                                 </div>
//                             )}
//                         </Col>
//                         <Col lg={4} md={12} className="column-padding-remover">
//                             <div className="cart-page-product-list-col cart-product-price-col">
//                                 <div className="cart-product-price-title">{t("Price Details")}</div>
//                                 <hr className="cart-product-price-hr" />
//                                 <dl className="cart-product-price-dl">
//                                     <dt className="cart-product-label-1">{t("Subtotal")}</dt>
//                                     <dd className="cart-product-subtotal"><MdOutlineCurrencyRupee /> {parseFloat(priceDetails?.amount)?.toFixed(2)}</dd>
//                                 </dl>
//                                 <dl className="cart-product-price-dl">
//                                     <dt className="cart-product-label-1">{t("Discount")}</dt>
//                                     <dd className="cart-product-dis-dd">- <MdOutlineCurrencyRupee /> {parseFloat(priceDetails?.discount)?.toFixed(2)}</dd>
//                                 </dl>
//                                 <hr className="cart-product-price-hr" />
//                                 <dl className="cart-product-price-dl">
//                                     <dt className="cart-product-label-2">{t("Total price")}</dt>
//                                     <dd className="cart-product-final-price-dd">
//                                         <strong><MdOutlineCurrencyRupee /> {parseFloat(priceDetails?.total)?.toFixed(2)}</strong>
//                                     </dd>
//                                 </dl>
//                             </div>
//                             <div className="cart-page-btns-div">
//                                 <Button
//                                     className="cart-page-cancel-btn"
//                                     onClick={() => {
//                                         LogEvent('Cart_Cancel_Clicked')
//                                         navigate('/')
//                                     }}
//                                 >
//                                     {t("Cancel")}
//                                 </Button>
//                                 <Button
//                                     className="cart-page-ok-btn"
//                                     onClick={handleCheckout}
//                                 >
//                                     {t("Continue")}
//                                 </Button>
//                             </div>
//                         </Col>
//                     </>
//                 }
//             </Row>

//         </Container>
//     )
// }

import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import cartActions from "../../Redux/cart/actions";
import { EncryptFunction } from "../../utils/cryptoFunction";
import { PiTrash } from "react-icons/pi";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { LogEvent } from "../../utils/firebase";
import RelatedProducts from "../productDetails/relatedProducts";
import productActions from "../../Redux/Products/action";
import { RiH1 } from "react-icons/ri";
// import productActions from '../../Redux/Products/action';

export default function CartIndex() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const { relatedProducts, productDetails, ProductList, productRateing } =
    useSelector((state) => state.ProductListReducer);
  const { cartProducts } = useSelector((state) => state.cartReducer);
  const qtyList = Array.from({ length: 10 }, (_, index) => index + 1);
  const [priceDetails, setPriceDetails] = useState({
    amount: 0,
    discount: 0,
    total: 0,
  });

  useEffect(() => {
    navigate(`/cart?id=${localStorage.getItem("auth_user")}`);
    dispatch({
      type: cartActions.GET_CART_PRODUCT_LIST,
      payload: { id: localStorage.getItem("auth_user") },
    });
  }, []);

  useEffect(() => {
    if (id) {
      dispatch({
        type: cartActions.GET_CART_PRODUCT_LIST,
        payload: { id: id },
      });
    } else if (
      localStorage.getItem("token") &&
      localStorage.getItem("auth_user")
    ) {
      navigate(`/cart?id=${localStorage.getItem("auth_user")}`);
      dispatch({
        type: cartActions.GET_CART_PRODUCT_LIST,
        payload: { id: localStorage.getItem("auth_user") },
      });
    }
    //eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (cartProducts && cartProducts?.order_items?.length > 0) {
      let array = { amount: 0, discount: 0, total: 0 };
      //eslint-disable-next-line
      cartProducts?.order_items?.map((val) => {
        let product_rate = parseFloat(val?.rate) * parseInt(val?.quantity);
        array["amount"] =
          parseFloat(array["amount"]) + parseFloat(product_rate);
        let product_dis = parseFloat(val?.discount) * parseInt(val?.quantity);
        array["discount"] =
          parseFloat(array["discount"]) + parseFloat(product_dis);
          
      });
      array["total"] = array["amount"] - array["discount"];
      setPriceDetails(array);
      
    }
   
  }, [cartProducts]);

  const handleRemoveProductFromCart = (value) => {
    LogEvent("Cart_Product_Remove_Clicked");
    dispatch({
      type: cartActions.REMOVE_PRODUCT_FROM_CART,
      payload: { id: value?.id, order_id: value?.order_id, user_id: id },
    });
  };

  useEffect(() => {
    if (ProductList?.data?.length > 0) {
      let relatedProducts = [];
      //eslint-disable-next-line
      productDetails?.related_products?.filter((val) => {
        if (ProductList?.data?.find((item) => item?.id === val)) {
          relatedProducts.push(
            ProductList?.data?.find((item) => item?.id === val)
          );
        }
      });
      dispatch({
        type: productActions.SET_RELATED_PRODUCTS,
        payload: relatedProducts,
      });
    }
    //eslint-disable-next-line
  }, [ProductList?.data, productDetails]);

  const handleCheckout = (e) => {
    e.preventDefault();
    LogEvent("Cart_Continue_Clicked");
    navigate(`/checkout?id=${localStorage.getItem("auth_user") ?? id}`);
    // window.location.href=`/checkout?id=${localStorage.getItem("auth_user") ?? id}`
    // window.location.reload();
  };

  const handleQtyChange = (e, value) => {
    dispatch({
      type: cartActions.CURRENT_ADDING_CART_PRODUCT,
      payload: {
        order_items: [
          {
            product_id: value?.product_id,
            rate: value?.rate,
            quantity: e.target.value,
            name: value?.name,
            discount: value?.discount,
            zoho_item_id: 0,
            gst: value?.gst,
            coupon_discount: value?.coupon_discount,
          },
        ],
      },
    });
    dispatch({
      type: cartActions.ADD_PRODUCT_TO_CART,
      payloadData: { type: "qty_update" },
    });
  };

  const handleViewProductDetails = (id) => {
    LogEvent("Cart_Product_Clicked");
    const encrypt = EncryptFunction(id);
    if (encrypt) {
      navigate(`/product-details?id=${encrypt}`);
    }
  };

  return (
    <>
      {/* <Container
        style={{
          minHeight: "calc(97vh)",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Row className="row-padding-remover" style={{ marginBottom: "20px" }}>
          {cartProducts?.length === 0 ||
          cartProducts?.order_items?.length === 0 ? (
            <Col
              md={12}
              className="d-flex justify-content-center"
              style={{ marginBottom: "30px" }}
            >
              <div className="text-center">
                <img
                  src={require("../../assets/images/emptycart.png")}
                  alt=""
                  className="empty-cart-img"
                />
                <div className="cart-noproducts-text">
                  {t("No Items Found in Cart")}
                </div>
                <a href="/products?category=herbal" className="btn btn-primary">
                  {t("Add items to cart")}
                </a>
              </div>
            </Col>
          ) : (
            <>
              <Col lg={8} md={12} className="cart-page-product-list-col">
                {cartProducts?.order_items?.map((val, i) => (
                  <div
                    className="cart-page-product-image-name-div cursor-pointer"
                    key={i}
                  >
                    <div
                      className="card-product-details-div"
                      onClick={() => handleViewProductDetails(val?.product_id)}
                    >
                      <img
                        src={val?.image_url}
                        className="cart-product-img"
                        alt="..."
                      />
                      <div className="cart_product_details_content">
                        <div className="cart-product-name">{val?.name}</div>
                        <div className="cart-product-weight-cls">
                          {val?.package_weight_in_gram} gm
                        </div>
                        <div className="product_page_price_main_div_cls">
                          {val?.discount > 0 ? (
                            <>
                              <span className="product-list-view-product-amount-del-tag me-1">
                                <MdOutlineCurrencyRupee />
                                <del>{parseFloat(val?.rate)}</del>
                              </span>
                              <span className="product-final-price ms-1">
                                {" "}
                                <MdOutlineCurrencyRupee />
                                {parseFloat(val?.rate) -
                                  parseFloat(val?.discount)}
                                /-
                              </span>
                            </>
                          ) : (
                            <span className="product-final-price">
                              <MdOutlineCurrencyRupee />
                              {parseFloat(val?.rate)}/-
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="cart-product-qty-maind-cls">
                      <div className="cart-produtcs-qty-label">
                        {t("Quantity")} :
                      </div>
                      <select
                        className="cart-product-select-qty-tag"
                        onChange={(e) => handleQtyChange(e, val)}
                        defaultValue={val?.quantity}
                      >
                        {Array.from(
                          {
                            length:
                              val.product.max_qty - val.product.min_qty + 1,
                          },
                          (_, index) => val.product.min_qty + index
                        ).map((qty, ind) => (
                          <option defaultValue={qty} key={ind}>
                            {qty}
                          </option>
                        ))}
                      </select>
                      <div
                        style={{ color: "#e10000", marginTop: "18px" }}
                        onClick={() => handleRemoveProductFromCart(val)}
                      >
                        <PiTrash size={18} /> {t("Remove")}
                      </div>
                    </div>
                  </div>
                ))}
              </Col>
              <Col lg={4} md={12} className="column-padding-remover">
                <div className="cart-page-product-list-col cart-product-price-col">
                  <div className="cart-product-price-title">
                    {t("Price Details")}
                  </div>
                  <hr className="cart-product-price-hr" />
                  <dl className="cart-product-price-dl">
                    <dt className="cart-product-label-1">{t("Subtotal")}</dt>
                    <dd className="cart-product-subtotal">
                      <MdOutlineCurrencyRupee />{" "}
                      {parseFloat(priceDetails?.amount)?.toFixed(2)}
                    </dd>
                  </dl>
                  <dl className="cart-product-price-dl">
                    <dt className="cart-product-label-1">{t("Discount")}</dt>
                    <dd className="cart-product-dis-dd">
                      - <MdOutlineCurrencyRupee />{" "}
                      {parseFloat(priceDetails?.discount)?.toFixed(2)}
                    </dd>
                  </dl>
                  <hr className="cart-product-price-hr" />
                  <dl className="cart-product-price-dl">
                    <dt className="cart-product-label-2">{t("Total price")}</dt>
                    <dd className="cart-product-final-price-dd">
                      <strong>
                        <MdOutlineCurrencyRupee />{" "}
                        {parseFloat(priceDetails?.total)?.toFixed(2)}
                      </strong>
                    </dd>
                  </dl>
                </div>
                <div className="cart-page-btns-div">
                  <Button
                    className="cart-page-cancel-btn"
                    onClick={() => {
                      LogEvent("Cart_Cancel_Clicked");
                      navigate("/");
                    }}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button className="cart-page-ok-btn" onClick={handleCheckout}>
                    {t("Continue")}
                  </Button>
                </div>
              </Col>
              {relatedProducts?.length > 0 && (
                <Container
                  fluid
                  className="product_details_related_products_div_for_bg"
                >
                  <RelatedProducts />
                </Container>
              )}
            </>
          )}
        </Row>
      </Container> */}

      <div>
        {cartProducts?.length === 0 ||
        cartProducts?.order_items?.length === 0 ? (
          <Container
            style={{
              minHeight: "calc(97vh)",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Row
              className="row-padding-remover"
              style={{ marginBottom: "20px" }}
            >
              <Col
                md={12}
                className="d-flex justify-content-center"
                style={{ marginBottom: "30px" }}
              >
                <div className="text-center">
                  <img
                    src={require("../../assets/images/emptycart.png")}
                    alt=""
                    className="empty-cart-img"
                  />
                  <div className="cart-noproducts-text">
                    {t("No Items Found in Cart")}
                  </div>
                  <a
                    href="/products?category=herbal"
                    className="btn btn-primary"
                  >
                    {t("Add items to cart")}
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        ) : (
            <Container
            className="my-4"
            style={{
              minHeight: "calc(97vh)"
            }}
          >
            <Row
              className="row-padding-remover"
              style={{ marginBottom: "20px" }}
            >
                <Col lg={8} md={12} className="cart-page-product-list-col">
                {cartProducts?.order_items?.map((val, i) => (
                  <div
                    className="cart-page-product-image-name-div cursor-pointer"
                    key={i}
                  >
                    <div
                      className="card-product-details-div"
                      onClick={() => handleViewProductDetails(val?.product_id)}
                    >
                      <img
                        src={val?.image_url}
                        className="cart-product-img"
                        alt="..."
                      />
                      <div className="cart_product_details_content">
                        <div className="cart-product-name">{val?.name}</div>
                        <div className="cart-product-weight-cls">
                          {val?.package_weight_in_gram} gm
                        </div>
                        <div className="product_page_price_main_div_cls">
                          {val?.discount > 0 ? (
                            <>
                              <span className="product-list-view-product-amount-del-tag me-1">
                                <MdOutlineCurrencyRupee />
                                <del>{parseFloat(val?.rate)}</del>
                              </span>
                              <span className="product-final-price ms-1">
                                {" "}
                                <MdOutlineCurrencyRupee />
                                {parseFloat(val?.rate) -
                                  parseFloat(val?.discount)}
                                /-
                              </span>
                            </>
                          ) : (
                            <span className="product-final-price">
                              <MdOutlineCurrencyRupee />
                              {parseFloat(val?.rate)}/-
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="cart-product-qty-maind-cls">
                      <div className="cart-produtcs-qty-label">
                        {t("Quantity")} :
                      </div>
                      <select
                        className="cart-product-select-qty-tag"
                        onChange={(e) => handleQtyChange(e, val)}
                        defaultValue={val?.quantity}
                      >
                        {Array.from(
                          {
                            length:
                              val.product.max_qty - val.product.min_qty + 1,
                          },
                          (_, index) => val.product.min_qty + index
                        ).map((qty, ind) => (
                          <option defaultValue={qty} key={ind}>
                            {qty}
                          </option>
                        ))}
                      </select>
                      <div
                        style={{ color: "#e10000", marginTop: "18px" }}
                        onClick={() => handleRemoveProductFromCart(val)}
                      >
                        <PiTrash size={18} /> {t("Remove")}
                      </div>
                    </div>
                  </div>
                ))}
              </Col>
              <Col lg={4} md={12} className="column-padding-remover">
                <div className="cart-page-product-list-col cart-product-price-col">
                  <div className="cart-product-price-title">
                    {t("Price Details")}
                  </div>
                  <hr className="cart-product-price-hr" />
                  <dl className="cart-product-price-dl">
                    <dt className="cart-product-label-1">{t("Subtotal")}</dt>
                    <dd className="cart-product-subtotal">
                      <MdOutlineCurrencyRupee />{" "}
                      {parseFloat(priceDetails?.amount)?.toFixed(2)}
                    </dd>
                  </dl>
                  <dl className="cart-product-price-dl">
                    <dt className="cart-product-label-1">{t("Discount")}</dt>
                    <dd className="cart-product-dis-dd">
                      - <MdOutlineCurrencyRupee />{" "}
                      {parseFloat(priceDetails?.discount)?.toFixed(2)}
                    </dd>
                  </dl>
                  <hr className="cart-product-price-hr" />
                  <dl className="cart-product-price-dl">
                    <dt className="cart-product-label-2">{t("Total price")}</dt>
                    <dd className="cart-product-final-price-dd">
                      <strong>
                        <MdOutlineCurrencyRupee />{" "}
                        {parseFloat(priceDetails?.total)?.toFixed(2)}
                      </strong>
                    </dd>
                  </dl>
                </div>
                <div className="cart-page-btns-div">
                  <Button
                    className="cart-page-cancel-btn"
                    onClick={() => {
                      LogEvent("Cart_Cancel_Clicked");
                      navigate("/");
                    }}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button className="cart-page-ok-btn" onClick={handleCheckout}>
                    {t("Continue")}
                  </Button>
                </div>
              </Col>
              {relatedProducts?.length > 0 && (
                <Container
                  fluid
                  className="product_details_related_products_div_for_bg"
                >
                  <RelatedProducts />
                </Container>
              )}
                </Row>
                </Container>
        )}
      </div>
    </>
  );
}

import checkoutActions from "./actions";
import { put, all, call, takeEvery, select } from 'redux-saga/effects';
import { API_URL, BILLING_EMAIL, CCAVANUE_PAYMENT_GATEWAY_REDIRECT_URL, CCAVANUE_TEST_ACCESS_CODE } from '../../utils/constant';
import axios from "axios";
import { DecryptFunction, EncryptFunction } from "../../utils/cryptoFunction";
import moment from "moment-timezone";
import commonActions from "../common/actions";

const checkoutSagas = function* () {
    yield all([
        yield takeEvery(checkoutActions.GET_DELIVERY_TYPE_LIST, getDeliveryTypeList),
        yield takeEvery(checkoutActions.CALCUATE_GST, calculateGST),
        yield takeEvery(checkoutActions.CALCULATE_SHIPPING, calculateShipping),
        yield takeEvery(checkoutActions.CREATE_NEW_ORDER, createNewOrder),
        yield takeEvery(checkoutActions.CREATE_ORDER_NUMBER, createOrderNumber),
        yield takeEvery(checkoutActions.PAYMENT_ENCODER, paymentEncoder),
        yield takeEvery(checkoutActions.PAYMENT_DECODER, paymentDecoder),
        yield takeEvery(checkoutActions.PAYMENT_VERIFY_COD, paymentVerifyCod),
        yield takeEvery(checkoutActions.UPDATE_USER_COUPON, updateUserCoupon),
        yield takeEvery(checkoutActions.APPLY_ORDER_COUPON, applyOrderCoupon),
        yield takeEvery(checkoutActions.PAYMENT_VERIFY_ONLINE, paymentVerifyOnline),
        yield takeEvery(checkoutActions.CCAVENUE_PAYMENT_GATEWAY, ccavanuePaymentGateway),  //chech-1
    ])
};

const getDeliveryTypeList = function* () {
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/v1/user/shippingproviders`)
        );
    
        if (result?.data?.code === 200) {
            const uniqueSet = new Set();
            // console.log("stage-1",uniqueSet)
            const uniqueArray = result?.data?.data.filter((item) => {
                // console.log("stage-2",item)
                const key = `${item.shipping_type}-${item.delivery_range}`;
                if (!uniqueSet.has(key)) {
                    uniqueSet.add(key);
                    return true;
                }
                return false;
            });
            // console.log("stage-3",uniqueArray)
            yield put({ type: checkoutActions.SET_DELIVERY_TYPE_LIST, payload: uniqueArray });
        }
    } catch (err) {
        console.log(err);
    };
};

const calculateGST = function* () {

    try {
        const { cartProducts } = yield select((state) => state.cartReducer);
        const { checkoutValues, deliveryType } = yield select((state) => state.checkoutReducer);
        // console.log("deliveryType stage-009",deliveryType)
        const { userAddress } = yield select(state => state.userReducer);
        if (cartProducts && cartProducts?.order_items?.length > 0) {
            // const gstPercentage = parseFloat(cartProducts?.order_items[0]?.gst);
            let priceObj = { amount: 0, discount: 0, total: 0, package_weight_in_gram: 0 }
            //eslint-disable-next-line
            cartProducts?.order_items?.map((val) => {
                let product_rate = parseFloat(val?.rate) * parseInt(val?.quantity)
                priceObj['amount'] = parseFloat(priceObj['amount']) + parseFloat(product_rate);
                let product_dis = parseFloat(val?.discount) * parseInt(val?.quantity)
                priceObj['discount'] = parseFloat(priceObj['discount']) + parseFloat(product_dis);
                let weight = parseFloat(val?.package_weight_in_gram) * parseInt(val?.quantity);
                priceObj['package_weight_in_gram'] = parseFloat(priceObj['package_weight_in_gram']) + parseFloat(weight);
            });
            priceObj['total'] = priceObj['amount'] - priceObj['discount'];
            let coupon_discount = checkoutValues?.coupon_discount || 0;
            const total = priceObj['total'] - coupon_discount;
            // const gstAmountValue = (discountedPrice * gstPercentage) / 100;
            // const total = discountedPrice + gstAmountValue;
            const wight_in_kg = priceObj['package_weight_in_gram'] / 1000;
            yield put({
                type: checkoutActions.CHECKOUT_VALUES, payload: {
                    ...checkoutValues,
                    DeliveryAddress: ((userAddress?.length > 0 && userAddress?.find(val => val.default_address === 1)) ? userAddress?.find(val => val.default_address === 1) : userAddress[0]),
                    DeliveryType: (deliveryType?.length > 0 && deliveryType?.find(val => val?.shipping_type === 'Express')),
                    PaymentType: 1,
                    product_amount: priceObj?.amount,
                    discount: priceObj?.discount,
                    total: total,
                    // gst_amount: gstAmountValue,
                    wight_in_kg: wight_in_kg
                }
            });
            if (checkoutValues?.DeliveryAddress?.state && checkoutValues?.DeliveryType?.id) {
                yield put({
                    type: checkoutActions.CALCULATE_SHIPPING, payload: {
                        "weight_kg": wight_in_kg,
                        "state": checkoutValues?.DeliveryAddress?.state,
                        "provider_id": checkoutValues?.DeliveryType?.id,
                        "order_value": total,
                        "shipping_type": checkoutValues?.DeliveryType?.shipping_type
                        // "shipping_type":val?.shipping_type === 'Express'
                        
                    }
                })
            }
        }
    } catch (err) {
        console.log(err);
    };
};

const calculateShipping = function* (data) {
    const { payload } = data;
    
    
// console.log("data",payload)
    const { checkoutValues } = yield select((state) => state.checkoutReducer);
    try {
        const { cartProducts } = yield select((state) => state.cartReducer);
        

        const result = yield call(() =>
            axios.post(`${API_URL}/v1/user/calculateshipping`, payload)
        );
        
        if (result?.data?.code === 200) {
            const gstPercentage = parseFloat(cartProducts?.order_items[0]?.gst);
            let priceObj = { amount: 0, discount: 0, total: 0 }
            //eslint-disable-next-line
            cartProducts?.order_items?.map((val) => {
                let product_rate = parseFloat(val?.rate) * parseInt(val?.quantity)
                priceObj['amount'] = parseFloat(priceObj['amount']) + parseFloat(product_rate);
                let product_dis = parseFloat(val?.discount) * parseInt(val?.quantity)
                priceObj['discount'] = parseFloat(priceObj['discount']) + parseFloat(product_dis);
            });
            priceObj['total'] = priceObj['amount'] - priceObj['discount'];
            let coupon_discount = checkoutValues?.coupon_discount || 0;
            const discountedPrice = priceObj['total'] - coupon_discount;
            // const gstAmountValue = (discountedPrice * gstPercentage) / 100;
            const total = discountedPrice ;
            // const total = discountedPrice + gstAmountValue;
            let finalTotal = parseFloat(total), shippingCharge = 0;
            if (result?.data?.data?.rate) {
                if (checkoutValues?.PaymentType === 0) {
                    // finalTotal = parseFloat(total) + parseFloat(result?.data?.data?.rate) + parseFloat(result?.data?.data?.cod_charges);
                    finalTotal = parseFloat(total) + parseFloat(result?.data?.data?.rate)
                    // shippingCharge = parseFloat(result?.data?.data?.rate) + parseFloat(result?.data?.data?.cod_charges);
                    shippingCharge = parseFloat(result?.data?.data?.rate) 
                } else {
                    finalTotal = parseFloat(total) + parseFloat(result?.data?.data?.rate);
                    shippingCharge = parseFloat(result?.data?.data?.rate);
                };
            };
            
            yield put({
                type: checkoutActions.CHECKOUT_VALUES,
                payload: { ...checkoutValues, total: finalTotal, shippingCharge: shippingCharge, shippingChanges: result?.data?.data }
            });

            // dispatch({
            //     type: checkoutActions.CHECKOUT_VALUES,
            //     payload: { ...checkoutValues, total: finalTotal, shippingCharge: shippingCharge, shippingChanges: result?.data?.data },
            //   });
            // window.location.reload()
        }
    } catch (err) {
        console.log(err);
    };
    // }
};

const createNewOrder = function* (data) {
    const { payload } = data;
    const { navigate } = payload;

    try {
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
        const result = yield call(() =>
            axios.put(`${API_URL}/v1/user/neworder`, payload?.data)
        );
        if (result?.data?.code === 200) {
            yield put({
                type: checkoutActions.CREATE_ORDER_NUMBER, payload: {
                    data: {
                        id: result?.data?.data[0]?.id,
                        amount: payload?.data?.total_amount
                    },
                    payment_type: payload?.data?.payment_type,
                    navigate: navigate
                }
            });
        };
    } catch (err) {
        console.log(err);
    };
};

const createOrderNumber = function* (data) {
    const { payload } = data;
    const { navigate } = payload;

    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/v1/payment`, payload?.data)
        );
        const { checkoutValues } = yield select((state) => state.checkoutReducer);
        const EncryptData = EncryptFunction((checkoutValues)?.toString());
        sessionStorage.setItem("checkout_status", EncryptData);
        const user_id = DecryptFunction(localStorage.getItem('auth_user'));
        if (result?.data?.code === 200) {
            if (checkoutValues?.applied_Coupon?.discount_type === 1) {
                yield put({
                    type: checkoutActions.APPLY_ORDER_COUPON, payload: {
                        id: payload?.data?.id,
                        coupon_percentage: checkoutValues?.applied_Coupon?.discount
                    }
                });
            }
            if (payload?.payment_type === 0) {
                yield put({
                    type: checkoutActions.PAYMENT_VERIFY_COD, payload: {
                        data: {
                            // orderId: payload?.data?.id,
                            orderId: result?.data?.data?.id,
                            status: 'PENDING'
                        }, navigate: navigate
                    }
                });
            } else {
                if (user_id) {
                    if (checkoutValues?.applied_Coupon) {
                        yield put({ type: checkoutActions.UPDATE_USER_COUPON });
                    }
                    const { UserProfile } = yield select(state => state.AuthReducer);
                    yield put({
                        type: checkoutActions.PAYMENT_ENCODER, payload: {
                            data: {
                                amount: parseInt(parseFloat(checkoutValues?.total)?.toFixed(0)),
                                billing_name: UserProfile?.name,
                                billing_address: checkoutValues?.DeliveryAddress?.address_line3,
                                billing_city: checkoutValues?.DeliveryAddress?.city,
                                billing_state: checkoutValues?.DeliveryAddress?.state,
                                billing_zip: checkoutValues?.DeliveryAddress?.pincode,
                                billing_country: checkoutValues?.DeliveryAddress?.country,
                                billing_tel: UserProfile?.mobile_no,
                                billing_email: UserProfile?.email ? UserProfile?.email : BILLING_EMAIL,
                                // billing_email: UserProfile?.email ? UserProfile?.email : 'admin@yourfarm.co.in',
                                delivery_name: UserProfile?.name,
                                delivery_tel: UserProfile?.mobile_no,
                                delivery_address: checkoutValues?.DeliveryAddress?.address_line3,
                                delivery_city: checkoutValues?.DeliveryAddress?.city,
                                delivery_state: checkoutValues?.DeliveryAddress?.state,
                                delivery_zip: checkoutValues?.DeliveryAddress?.pincode,
                                delivery_country: checkoutValues?.DeliveryAddress?.country,
                                currency: 'INR',
                                language: 'EN',
                                order_id: payload?.data?.id,
                                merchant_param1: 'YOURFARM',
                                // merchant_param1: encodeURI(`${FRONTEND_URL}/checkout-response`),
                                // redirect_url: `http://localhost:3005/redirectpost`,
                                // cancel_url: `http://localhost:3005/redirectpost`
                                redirect_url: `${API_URL}/v1/payment/verify`,
                                cancel_url: `${API_URL}/v1/payment/verify`
                            },
                            navigate: navigate
                        }
                    });
                }
            };
        }
    } catch (err) {
        console.log(err);
    };
};

const paymentEncoder = function* (data) {
    const { payload } = data;
    const { navigate } = payload;

    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/v1/payment/encorder`, payload?.data)
        );
        if (result?.data?.code === 200) {
            
            yield put({ type: checkoutActions.CCAVENUE_PAYMENT_GATEWAY, payload: { data: result?.data?.data, navigate: navigate } });
        }
    } catch (err) {
        console.log(err);
    };
};

const paymentDecoder = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/v1/payment/decorder`, payload)
        );
        yield put({ type: checkoutActions.DECODER_RESPONSE, payload: result?.data });
        // if(result?.data?.order_status?.toUpperCase() === 'SUCCESS'){

        // }
    } catch (err) {
        console.log(err);
    };
};

const updateUserCoupon = function* () {

    try {
        const { checkoutValues } = yield select((state) => state.checkoutReducer);
        if (checkoutValues?.applied_Coupon?.id) {
            const userId = DecryptFunction(localStorage.getItem('auth_user'));
            const mobile_no = DecryptFunction(localStorage.getItem('auth_user_no'));
            if (userId && mobile_no) {
                const payloadData = {
                    "coupon_id": checkoutValues?.applied_Coupon?.id,
                    "redemption_user_id": userId,
                    "redemption_date": moment().format('YYYY-MM-DD'),
                    "mobile_no": mobile_no?.toString()
                }
                yield call(() =>
                    axios.put(`${API_URL}/v1/user/coupon_redemptions/${userId}`, payloadData)
                );
                // if (result?.data?.code === 200) {
                //     yield put({ type: checkoutActions.APPLY_ORDER_COUPON });
                // }
            }
        }
    } catch (err) {
        console.log(err);
    };
};

const applyOrderCoupon = function* (data) {
    const { payload } = data;

    try {
        yield call(() =>
            axios.put(`${API_URL}/v1/user/orders/coupon`, payload)
        );
    } catch (err) {
        console.log(err);
    };
};

const paymentVerifyCod = function* (data) {
    const { payload } = data;
    const { navigate } = payload;

    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/v1/payment/verify/cod`, payload?.data)
        );
        if (result?.data?.success === 'true') {
            const { checkoutValues } = yield select((state) => state.checkoutReducer);
            if (checkoutValues?.applied_Coupon) {
                yield put({ type: checkoutActions.UPDATE_USER_COUPON });
                // if (checkoutValues?.applied_Coupon?.discount_type === 1) {
                //     yield put({
                //         type: checkoutActions.APPLY_ORDER_COUPON, payload: {
                //             id: result?.data?.id,
                //             coupon_percentage: checkoutValues?.applied_Coupon?.discount
                //         }
                //     });
                // }
            };
            navigate('/PaymentSuccess');
            // navigate('/checkout-status');
            // yield put({ type: checkoutActions.SHOW_ORDER_SUCCESS_MODAL, payload: { isOpen: true, status: "success" } });
        } else {
            navigate('/PaymentFailed');
            // navigate('/checkout-status');
            // yield put({ type: checkoutActions.SHOW_ORDER_SUCCESS_MODAL, payload: { isOpen: true, status: "failed" } });
        };
    } catch (err) {
        console.log(err);
    };
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
};

const paymentVerifyOnline = function* () {

    try {
        yield call(() =>
            axios.post(`${API_URL}/v1/payment/verify`)
        );

    } catch (err) {
        console.log(err);
    };
};

const ccavanuePaymentGateway = function* (data) {
    const { payload } = data;
    const { navigate } = payload;
    try {
        let web_url = CCAVANUE_PAYMENT_GATEWAY_REDIRECT_URL;
        // let web_url = 'https://test.ccavenue.com/transaction/transaction.do';
        // let commamd = "initiateTransaction";
        let accces = CCAVANUE_TEST_ACCESS_CODE;
        // var html =
        //     `<html> <head><meta name='viewport' content='width=device-width, initial-scale=1.0'></head> 
        //     <body> <form id='redirect' name='redirect' method='post' action='${web_url}'>` +
        //     `<input type='hidden' name='command' value='${commamd}'/>` +
        //     `<input type='hidden' name='encRequest' value='${payload?.data}'/>` +
        //     `<input  type='hidden' name='access_code' value='${accces}'/>
        //     <script language='javascript'>document.redirect.submit ();</script></form></body></html>`;
        // ****************working code start**********************
        var html =
            `<html> <head><meta name='viewport' content='width=device-width, initial-scale=1.0'></head> 
            <body onload='document.f.submit();'> <form id='f' name='f' method='post' action='${web_url}?command=initiateTransaction'>` +
            // `<input type='hidden' name='command' value='${commamd}'/>` +
            `<input type='hidden' name='encRequest' value='${payload?.data}'/>` +
            `<input  type='hidden' name='access_code' value='${accces}'/></form></body></html>`;

        yield put({ type: checkoutActions.INITIATE_CCAVENUE_TRANSACTION_SUCCESS, payload: html });
        // ****************working code end**********************
        // yield put({
        //     type: checkoutActions.INITIATE_CCAVENUE_TRANSACTION_SUCCESS, payload: {
        //         web_url: web_url,
        //         access_code: accces,
        //         encRequest: payload?.data
        //     }
        // });
        navigate('/payment-gateway');
        // const apiUrl = 'https://test.ccavenue.com/transaction/transaction.do/transaction/transaction.do?command=initiateTransaction';
        // const corsAnywhereUrl = 'http://localhost:3000/' + apiUrl;
        // const response = yield call(() =>
        //     // axios.post(`${corsAnywhereUrl}`, {
        //     axios.post(`${CCAVANUE_PAYMENT_GATEWAY_REDIRECT_URL}?command=initiateTransaction`, {
        //         encRequest: payload,
        //         accessCode: CCAVANUE_TEST_ACCESS_CODE
        //     })
        // );   
        // window.location.href = response.data.redirectUrl;
    } catch (err) {
        console.log(err);
    };
};

export default checkoutSagas;
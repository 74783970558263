import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DecryptFunction } from "../../utils/cryptoFunction";
import { Button, Col, Container, Row, Modal } from "react-bootstrap";
import RelatedProducts from "./relatedProducts";
import { useDispatch, useSelector } from "react-redux";
import productActions from "../../Redux/Products/action";
import cartActions from "../../Redux/cart/actions";
import commonActions from "../../Redux/common/actions";
import userActions from "../../Redux/user/actions";
import { useTranslation } from "react-i18next";
import ProductImages from "./productImages";
import { Rating } from "react-simple-star-rating";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { priceRoundOff } from "../../utils/validation";
import { LogEvent } from "../../utils/firebase";
import Ratings from "../modal/rateingProductModal";
import imageViewer from "../modal/imageViewerModal";
import ImageViewer from "../modal/imageViewerModal";
import { TbTruckDelivery } from "react-icons/tb";
import { IoShieldCheckmarkOutline } from "react-icons/io5";
// import { RiDiscountPercentLine } from "react-icons/ri";
import { MdOutlineLocalOffer } from "react-icons/md";
import { CiDiscount1 } from "react-icons/ci";

export default function ProductDetailsIndex() {
  const { t } = useTranslation();
  const [rating, setRating] = useState(0);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const average_rating = searchParams.get("avg");

  const dispatch = useDispatch();

  const { relatedProducts, productDetails, ProductList, productRateing } =
    useSelector((state) => state.ProductListReducer);

  const { userAddress } = useSelector((state) => state.userReducer);

  const { cartProducts } = useSelector((state) => state.cartReducer);

  const { currentLanguage } = useSelector((state) => state.commonReducer);

  const [showReviewModal, setShowReviewModal] = useState(false); // Added state for modal visibility
  const [imageViewModal, setImageViewModal] = useState({
    show: false,
    data: [],
  }); // Added state for modal visibility
  

  // const qtyList = Array.from({ length: 10 }, (_, index) => index + 1);

  const [selectedQty, setSelectedQty] = useState();

  const openReviewModal = () => {
    setShowReviewModal(true);
  };

  const handleCloseReviewModal = () => {
    setShowReviewModal(false);
  };
  const handleImageClick = (value) => {
    setImageViewModal({ show: true, data: [value] });
  };

  useEffect(() => {
    dispatch({ type: productActions.GET_PRODUCTS_LIST });
    dispatch({
      type: productActions.SET_PRODUCTS_RATEING,
      payload: [],
    });
  }, []);

  useEffect(() => {
    if (id) {
      dispatch({ type: productActions.GET_PRODUCT_DETAILS, payload: id });
      dispatch({
        type: productActions.GET_PRODUCTS_RATEING,
        payload: id,
      });
    }
    //eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (ProductList?.data?.length > 0) {
      let relatedProducts = [];
      //eslint-disable-next-line
      productDetails?.related_products?.filter((val) => {
        if (ProductList?.data?.find((item) => item?.id === val)) {
          relatedProducts.push(
            ProductList?.data?.find((item) => item?.id === val)
          );
        }
      });
      dispatch({
        type: productActions.SET_RELATED_PRODUCTS,
        payload: relatedProducts,
      });
    }
    //eslint-disable-next-line
  }, [ProductList?.data, productDetails]);

  useEffect(() => {
    if (localStorage.getItem("token") && localStorage.getItem("auth_user")) {
      const decryptedId = DecryptFunction(localStorage.getItem("auth_user"));
      if (decryptedId) {
        dispatch({
          type: userActions.GET_USER_ADDRESS,
          payload: { id: decryptedId },
        });
      }
    }
    //eslint-disable-next-line
  }, []);

  const handleAddToCart = (e, type) => {
    e.preventDefault();
    let discountValue = 0;
    if (
      productDetails?.discount_status === 1 &&
      productDetails.discount_amount > 0
    ) {
      if (productDetails?.discount_type === 1) {
        discountValue =
          (productDetails?.amount * productDetails?.discount_amount) / 100;
      } else {
        discountValue = productDetails?.discount_amount;
      }
    }
    dispatch({
      type: cartActions.CURRENT_ADDING_CART_PRODUCT,
      payload: {
        order_items: [
          {
            product_id: productDetails?.id,
            rate: productDetails?.amount,
            quantity: selectedQty || 1,
            name:
              productDetails?.language?.find(
                (lang) => lang?.language_code === currentLanguage
              )?.medicine_name || productDetails?.medicine_name,
            discount: priceRoundOff(discountValue),
            zoho_item_id: 0,
            gst: productDetails?.gst,
            coupon_discount: 0,
          },
        ],
      },
    });
    if (localStorage.getItem("token")) {
      if (userAddress?.length === 0) {
        sessionStorage.setItem("addtocart", "Address");
        dispatch({
          type: commonActions.SET_SHOW_USER_ADDRESS_MODAL,
          payload: true,
        });
      } else {
        if (type === "buy") {
          LogEvent("Product_Details_BuyNow");
          dispatch({
            type: cartActions.BUY_NOW_ACTION,
            payloadData: { type: type, navigate: navigate },
          });
        } else {
          LogEvent("Product_Details_AddToCart");
          dispatch({ type: cartActions.ADD_PRODUCT_TO_CART });
        }
      }
    } else {
      sessionStorage.setItem("addtocart", "Login");
      dispatch({ type: commonActions.SET_SHOW_LOGIN_MODAL, payload: true });
    }
  };

  const handleQtyValue = (e) => {
    setSelectedQty(e.target.value);
  };

  const convertToKilograms = (grams) => {
    if (grams >= 1000) {
      return (grams / 1000).toFixed(2) + " Kg";
    } else {
      return grams + " gm";
    }
  };

  return (
    <Container fluid className="px-0">
      <Container className="my-5">
        <div className="page_heading_div_cls">{t("Product Details")}</div>
        <Row className="mt-4 custom-card-container">
          <Col md={6}>
            <div className="product-details-img-col">
              <ProductImages
                images={productDetails?.sliderImages}
                onClick={handleImageClick}
              />
              {productDetails?.discount_status === 1 && (
                <>
                  {productDetails?.discount_amount > 0 && (
                    <span className="product-discount-span">
                      <>
                        {productDetails?.discount_type === 1
                          ? `${productDetails?.discount_amount}% OFF`
                          : `- ₹${productDetails?.discount_amount} OFF`}
                      </>
                    </span>
                  )}
                </>
              )}
            </div>
            {imageViewer && (
              <ImageViewer
                showModal={imageViewModal?.show}
                onCancel={() => setImageViewModal({ show: false, data: [] })}
                images={imageViewModal?.data}
                productName={
                  productDetails?.language?.find(
                    (lang) => lang?.language_code === currentLanguage
                  )?.medicine_name
                }
              />
            )}
          </Col>
          <Col md={6}>
            <div className="product-details-col2-content-cls">
              <Row className="product-details-col2 row-padding-remover">
                <Col
                  md={12}
                  className="product-list-view-card-div product-details-card-col-padding"
                >
                  <div className="product-details-product-name cursor-pointer">
                    {productDetails?.language?.find(
                      (lang) => lang?.language_code === currentLanguage
                    )?.medicine_name || productDetails?.medicine_name}
                  </div>
                  <div className="product-details-ratings-main-div">
                    <Rating
                      count={5}
                      initialValue={average_rating}
                      size={28}
                      activeColor="#ffd700"
                      isHalf={true}
                      edit={false}
                      readonly
                    />
                    <div
                      className="product-details-ratings-text"
                      onClick={openReviewModal}
                      style={{ cursor: "pointer" }}
                    >
                      {average_rating > 0
                        ? t("Share your review")
                        : t("Be the first to review")}
                    </div>{" "}
                  </div>
                  <h6 className="product-details-description-label">
                    {t("Product Description")}
                  </h6>
                  <h6 className="product-details-desc">
                    {productDetails?.language?.find(
                      (lang) => lang?.language_code === currentLanguage
                    )?.discription || productDetails?.discription}
                  </h6>
                </Col>
                <Col
                  md={12}
                  className="product-list-view-card-div product-details-card-col-padding"
                >
                  <div className="d-flex justify-content-between flex-wrap">
                    <div>
                      <div className="product-details-package-weight-label">
                        {t("Available package weight")}{" "}
                      </div>
                      <div className="product-details-package-weight-value">
                        {" "}
                        {convertToKilograms(
                          productDetails?.package_weight_in_gram
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="product-details-package-weight-label">
                        {t("Quantity")}
                      </div>
                      <select
                        className="product-details-select-qty-tag"
                        onChange={handleQtyValue}
                        value={
                          selectedQty ||
                          cartProducts?.order_items?.find(
                            (q) => q.product_id === productDetails?.id
                          )?.quantity
                        }
                      >
                        {Array.from(
                          {
                            length:
                              productDetails?.max_qty -
                              productDetails?.min_qty +
                              1,
                          },
                          (_, index) => productDetails?.min_qty + index
                        ).map((qty, index) => (
                          <option defaultValue={qty} key={index}>
                            {qty}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="mt-5">
                    {productDetails?.discount_status === 1 ? (
                      <>
                        <del className="product-list-view-product-amount-del-tag me-1">
                          <MdOutlineCurrencyRupee />
                          {productDetails?.amount}
                        </del>
                        {productDetails?.discount_type === 1 ? (
                          <span className="product-final-price ms-1">
                            {" "}
                            <MdOutlineCurrencyRupee />
                            {priceRoundOff(
                              productDetails?.amount -
                                (productDetails?.amount *
                                  productDetails?.discount_amount) /
                                  100
                            )}
                            /-
                          </span>
                        ) : (
                          <span className="product-final-price ms-1">
                            <MdOutlineCurrencyRupee />
                            {priceRoundOff(
                              productDetails?.amount -
                                productDetails?.discount_amount
                            )}
                            /-
                          </span>
                        )}
                      </>
                    ) : (
                      <span className="product-final-price">
                        <MdOutlineCurrencyRupee />
                        {productDetails?.amount}/-
                      </span>
                    )}
                  </div>
                </Col>
                <Col
                  md={12}
                  sm={12}
                  className="mt-3 product_details_page_btns_col_cls"
                  style={{ padding: "0" }}
                >
                  <Button
                    className="product-details-addtocart"
                    onClick={(e) => handleAddToCart(e, "cart")}
                  >
                    {t("Add To Cart")}
                  </Button>

                  {/* <Button
                                        className="product-details-buynow-btn"
                                        onClick={(e) => handleAddToCart(e, 'buy')}
                                    >
                                        {t("Buy Now")}
                                    </Button> */}
                </Col>

                <Col
                  md={12}
                  sm={12}
                  className="mt-3 product_details_page_btns_col_cls"
                  style={{ padding: "0" }}
                >
                  <Button
                    className="product-details-buynow-btn"
                    onClick={(e) => handleAddToCart(e, "buy")}
                  >
                    {t("Buy Now")}
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>

          <Row className="mt-4">
            <Col md={6}>
              <div>
                {/* <div className="sample-images-row">
                {productDetails?.sliderImages.slice(0, 4).map((item, index) => (
                    <div key={index} className="sample-image">
                        {item?.url ? (
                            <img src={item.url} alt="" className="sample-img" />
                        ) : (
                            <div className="no-image-placeholder">No Image</div>
                        )}
                    </div>
                ))}
            </div> */}
                <div className="sample-images-row">
                  {productDetails?.sliderImages
                    .slice(0, 4)
                    .map((item, index) => (
                      <div key={index} className="sample-image">
                        {item?.url ? (
                          item?.type === "video" ? (
                            <video controls className="sample-video">
                              <source src={item?.url} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img
                              src={item?.url}
                              alt=""
                              className="sample-img"
                            />
                          )
                        ) : (
                          <div className="no-image-placeholder">No Image</div>
                        )}
                      </div>
                    ))}
                </div>

                <div className="feature">
                  <TbTruckDelivery className="product-deatil-icon" />
                  <p>{t("FREE shipping on orders above ₹499")}</p>
                </div>
                <div className="feature">
                  <IoShieldCheckmarkOutline className="product-deatil-icon" />
                  <p>{t("Quality you can trust")}</p>
                </div>
                <div className="feature">
                  <CiDiscount1 className="product-deatil-icon" />
                  <p>{t("Enjoy extra discounts with our coupons")}</p>
                </div>
                {/* <div className="feature">
                <MdOutlineLocalOffer className="product-deatil-icon" />
                <p>Lorem Ipsum</p>
            </div> */}
              </div>
            </Col>
            <Col md={6}>
              <div className="product-rating-div product-details-card-col-padding mt-2">
                <div className="page_heading_rating">
                  {t("Review & Ratings")}
                </div>
                {productRateing?.data?.length > 0 ? (
                  productRateing.data.map((item, index) => (
                    <div key={index}>
                      <div className="rating-review">
                        {item?.user?.img_url && (
                          <img
                            src={item.user.img_url}
                            alt=""
                            className="user-icon-circle"
                          />
                        )}
                        <span className="nearby-location">
                          {item?.user?.name}
                        </span>
                        <div className="user-details">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Rating
                              count={5}
                              initialValue={item?.rating}
                              size={28}
                              activeColor="#ffd700"
                              readonly
                            />
                            <div
                              className="post-date"
                              style={{ marginLeft: "10px" }}
                            >
                              {item?.updated_at
                                ? new Date(item.updated_at).toLocaleDateString(
                                    "en-GB",
                                    {
                                      day: "2-digit",
                                      month: "long",
                                      year: "numeric",
                                    }
                                  )
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="review-description">
                        <p>{item.review_comments}</p>
                      </div>
                      <hr />
                    </div>
                  ))
                ) : (
                  <div className="no-reviews">
                    <p>{t("No reviews yet")}</p>
                  </div>
                )}
              </div>
            </Col>
          </Row>

          {/* {productRateing?.data?.length > 0 && (
                        <Row className="mt-4">
                            <Col md={6}>
                                <div>
                                    <div className="sample-images-row">
                                        {productDetails?.sliderImages.slice(0, 4).map((item, index) => (
                                            <div key={index} className="sample-image">
                                                {item?.url ? (
                                                    <img src={item.url} alt="" className="sample-img" />
                                                ) : (
                                                    <div className="no-image-placeholder">No Image</div>
                                                )}
                                            </div>
                                        ))}
                                    </div>

                                    <div class="feature">
                                        <TbTruckDelivery class="product-deatil-icon" />
                                        <p>FREE shipping on orders above ₹40</p>
                                    </div>
                                    <div class="feature">
                                        <IoShieldCheckmarkOutline class="product-deatil-icon" />
                                        <p>Quality you can trust</p>
                                    </div>
                                    <div class="feature">
                                        <CiDiscount1 class="product-deatil-icon" />
                                        <p>Enjoy extra discounts with our coupons</p>
                                    </div>
                                    <div class="feature">
                                        <MdOutlineLocalOffer class="product-deatil-icon" />
                                        <p>Lorem Ipsum</p>
                                    </div>

                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="product-rating-div product-details-card-col-padding mt-2">
                                    <div className="page_heading_rating">
                                        {t("Review & Ratings")}
                                    </div>
                                    {productRateing.data.map((item, index) => (
                                        <div key={index}>

                                            <div className="rating-review">
                                                {item?.user?.img_url && (
                                                    <img src={item.user.img_url} alt="" className="user-icon-circle" />
                                                )}
                                                <span className="nearby-location">{item?.user?.name}</span>
                                                <div className="user-details">
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Rating
                                                            count={5}
                                                            initialValue={item?.rating}
                                                            size={28}
                                                            activeColor="#ffd700"
                                                            readonly
                                                        />
                                                        <div className="post-date" style={{ marginLeft: '10px' }}>
                                                            {item?.updated_at ? new Date(item.updated_at).toLocaleDateString('en-GB', { day: '2-digit', month: 'long', year: 'numeric' }) : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="review-description">
                                                <p>{item.review_comments}</p>
                                            </div>
                                            <hr />
                                        </div>
                                    ))}
                                </div>
                            </Col>
                        </Row>
                    )} */}
        </Row>
      </Container>
      {relatedProducts?.length > 0 && (
        <Container
          fluid
          className="product_details_related_products_div_for_bg"
        >
          <RelatedProducts />
        </Container>
      )}

      {showReviewModal && (
        <Ratings
          showModal={showReviewModal}
          onCancel={() => setShowReviewModal(false)}
        />
      )}
    </Container>
  );
}

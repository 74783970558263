import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Slider from 'react-slick';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import NoImage from '../../assets/images/noImage.jpg';
import { LogEvent } from "../../utils/firebase";
import { MdOutlineArrowForwardIos, MdOutlineArrowBackIos } from "react-icons/md";
import { Container } from "react-bootstrap";
import { settings } from "firebase/analytics";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { useRef } from "react";

const BlogPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { homeContent } = useSelector(state => state.AuthReducer);
  const [blogList, setBlogList] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);

  const swiperRef = useRef(null)

  useEffect(() => {
    if (homeContent?.length > 0) {
      const filteredBlogs = homeContent.filter(val => val?.type === 'blog').slice(3, 10);
      setBlogList(filteredBlogs);
      setTotalSlides(filteredBlogs.length);
    }
  }, [homeContent]);

  const handleViewMore = () => {
    LogEvent('Home_Blogs_ViewAll');
    navigate('/blog');
  };

  const handleAfterChange = (current) => {
    setCurrentSlide(current);
  };


  // const settings = {
  //   centerMode: true,
  //   infinite: true,
  //   centerPadding: "10",
  //   slidesToShow: 3,
    
  //   autoplaySpeed: 4000,
  //   speed: 500,
  //   dots: true,
  //   focusOnSelect: true,
  //   afterChange: handleAfterChange,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 767,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  //   nextArrow: <MdOutlineArrowForwardIos />,
  //   prevArrow: <MdOutlineArrowBackIos />,
  // };


  // const settings1 = {
  //   centerMode: true,      
  //   infinite: true,        
  //   centerPadding: '0px',  
  //   slidesToShow: 3,       
  //   speed: 500,            
  //   focusOnSelect: true,   
  //   responsive: [
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 1,
  //         centerMode: true,
  //       },
  //     },
  //   ],
  //   nextArrow: <MdOutlineArrowForwardIos />,
  //   prevArrow: <MdOutlineArrowBackIos />,
  // };

  // const settings2 = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   autoplaySpeed: 2000,
   
  //   slidesToShow: 3,  
  //   slidesToScroll: 1,
  //   arrows: true,
  //   centerMode: true,  
  //   centerPadding: '0px',
  //   responsive: [{
  //     breakpoint: 768,
  //     settings: {
  //       arrows: false,
  //           }
  // }],  

  //   nextArrow: <MdOutlineArrowForwardIos />,
  //   prevArrow: <MdOutlineArrowBackIos />,
  // };
  


  return (
    <Container fluid className="farm-equipments" style={{ backgroundColor: "#F6F7FF" }}>
      <Container style={{position: 'relative'}}>
        <div style={{ textAlign: 'center', fontSize: '22px', color: '#232c63', margin: '20px 0px 30px 0px' }}>
          {t("Blogs")}
        </div>
        {/* <div className="mt-3">
          {blogList.length > 0 ? (
            <Slider {...settings} className="center-slider slider-arrow-custom-style-for-related-products">
              {blogList.map((blog, index) => (
                <div key={blog.id} className="blog-card-wrapper">
                  <a href={blog.link} target="_blank" rel="noopener noreferrer" className="mt-2" onClick={() => LogEvent('Home_Blogs_Details')}>
                    <div className="blog-card">
                      <img
                        src={blog.img_url || NoImage}
                        alt="Blog"
                        className="blogpageimg"
                      />
                    </div>
                  </a>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="h5 my-3 text-center">{t('No Data Found')}</div>
          )}
        </div> */}



      {/* <Slider {...settings2} className="demo slider-arrow-custom-style-for-related-products">
  {blogList.map((blog, index) => (
    <div className="slid" key={blog.id}>
      <a
        href={blog.link}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => LogEvent('Home_Blogs_Details')}
      >
        <div className="blog-card">
          <img src={blog.img_url || NoImage} alt="" />
        </div>
      </a>
    </div>
  ))}
</Slider> */}

<Swiper
            className="blog-swiper"
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={30}
            slidesPerView={3}
            navigation= {false}
            pagination={{ clickable: true }}
            centeredSlides={true}
            loop={true}
            // scrollbar={{ draggable: true }
            onSwiper={(swiper) => (swiperRef.current = swiper)}
            // onSlideChange={() => console.log("slide change")}
            
          >

            {blogList.map((blog, index) =>(
              
              <SwiperSlide>
                <a
              href={blog.link}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => LogEvent('Home_Blogs_Details')}
            >
                <img style={{width: '100%'}} src={blog.img_url || NoImage} alt="" />
                </a>
                </SwiperSlide>
              
            ))}
          </Swiper>



          {/* <CustomArrow direction="prev"/>
          <CustomArrow direction="next"/> */}

          <button

          className="custom-new-button"

    style={{
      backgroundColor: '#dc7f92',
      width: '45px',
      height: '45px',
      color: '#fff',
      borderRadius: '8px',
      border: 'none',
      position: 'absolute',
      top: '41%',
      left: '0%',
      zIndex: 5
    }}
    onClick={() => 
      swiperRef.current?.slidePrev()
    }
    >
      <MdOutlineArrowBackIos style={{fontSize: '34px'}}/>
    </button>

    <button

    className="custom-new-button"

    style={{
      backgroundColor: '#dc7f92',
      width: '45px',
      height: '45px',
      color: '#fff',
      borderRadius: '8px',
      border: 'none',
      position: 'absolute',
      top: '41%',
      right: '0%',
      zIndex: 5
    }}
    onClick={() => 
      swiperRef.current?.slideNext()
    }
    >
      <MdOutlineArrowForwardIos style={{fontSize: '34px'}}/>
    </button>


        <div style={{ textAlign: 'center', marginTop: '40px' }}>
          <div>
            <button
              className="btn btn-primary"
              style={{ backgroundColor: '#007bff', borderColor: '#007bff', color: '#fff', position: 'relative' }}
              onClick={handleViewMore}
            >
             {t('View More')}
            </button>
          </div>
        </div>
      </Container>
    </Container>
  );
};

export default BlogPage;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useNavigate } from "react-router-dom";
import market_Action from "../../Redux/marketPlace/action";
import Masitis from "../../assets/images/Common_diesase/Masitis.png";
import Reporoductive from "../../assets/images/Common_diesase/Reporoductive failure.png";
import Lumpy from "../../assets/images/Common_diesase/Lumpy skin.png";
import Foot from "../../assets/images/Common_diesase/foot and mouth.png";
import { MdOutlineArrowForwardIos, MdOutlineArrowBackIosNew } from "react-icons/md";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LogEvent } from "../../utils/firebase";

import { PiChatText } from "react-icons/pi";
import { DecryptFunction } from '../../utils/cryptoFunction';
import { IoCallOutline } from "react-icons/io5";


const CommonDiseasesPage = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentLang = i18n.language;

  const textStyle = {
    marginLeft: '8px',
    fontSize: currentLang === 'tm' ? '14px' : '16px',
    lineHeight: currentLang === 'tm' ? '1.5' : '1.6', 
  };
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        dispatch({
            type: market_Action.GET_CATEGORY_WISE_LISTINGS_DIARY_CATTLE,
            payload: 1,
        });
    }, [dispatch]);

    const handleViewMore = () => {
        LogEvent("Disease_Management");
        navigate("/treatments");
    };

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                },
            },
        ],
        prevArrow: <MdOutlineArrowBackIosNew />,
        nextArrow: <MdOutlineArrowForwardIos />,
    };

    const products = [
        {
            id: 1, image_url: Masitis,

            diesase: {
                en: 'Mastitis',
                tm: 'மடிநோய்',
                mr: 'स्तनदाह'
            },
            question: {
                en: 'Swollen or painful udder? Change in milk colour?',
                tm: 'மடி வீக்கமா  அல்லது வலி உள்ளதா ? பாலில் நிறம் மாற்றமா?',
                mr: 'सूज किंवा वेदनादायक कासे? दुधाच्या रंगात बदल?'
            },
            answer: {
                en: 'Mastitis is caused by microorganism and affects milk production. It needs immediate Veterinary doctor attention.',
                tm: 'மடிநோய் நுண்ணுயிரிகளால் ஏற்பட்டு பால் உற்பத்தியை பாதிக்கிறது. உடனடியாக கால்நடை மருத்துவரின் ஆலோசனை தேவை.',
                mr: 'स्तनदाह सूक्ष्मजीवांमुळे होतो आणि दूध उत्पादनावर परिणाम होतो. तात्काळ पशुवैद्यकीय डॉक्टरकडे लक्ष देणे आवश्यक आहे.'
            },
        },
        {
            id: 2, image_url: Reporoductive,
            diesase: {
                en: 'Reproductive failure',
                tm: 'இனப்பெருக்கத்திறன் இல்லாமை / சினை பிடிக்காமை ',
                mr: ' पुनरुत्पादक अपयश'
            },
            question: {
                en: 'Your cow is not pregnant even after 3 or more insemination? ',
                tm: '3 அல்லது அதற்கும் மேல் சினை ஊசி போட்ட  பிறகும் சினை பிடிக்கவில்லையா?',
                mr: 'तुमची गाय 3 किंवा त्याहून अधिक रेतनानंतरही गर्भवती नाही?'
            },
            answer: {
                en: 'Improper feeding, deficiency in micronutrition, hormonal imbalance  and untimely insemination are major cause for infertility.',
                tm: 'சரியான தீவனப் பராமரிப்பு இல்லாமை, நுண்ணூட்டச்சத்து குறைபாடு, ஹார்மோன் சமநிலையின்மை மற்றும் சரியான நேரத்தில் சினை ஊசி போடாதது ஆகியவை சினை பிடிக்காமை  முக்கிய காரணமாகும்.',
                mr: 'अयोग्य आहार, सूक्ष्म पोषणाची कमतरता, हार्मोनल असंतुलन आणि वेळेवर गर्भाधान हे वंध्यत्वाचे प्रमुख कारण आहेत.'
            },
        },
        {
            id: 3, image_url: Lumpy,
            diesase: {
                en: 'Lumpy Skin Disease',
                tm: 'மாடுகளில் ஏற்படும் பெரியம்மை நோய்',
                mr: 'ढेकूळ त्वचा रोग'
            },
            question: {
                en: 'Right solution for the diseased animal?',
                tm: 'பெரியம்மை நோயால் பாதிக்கப்பட்ட  கால்நடைகளுக்கு சரியான தீர்வு?',
                mr: 'रोगग्रस्त जनावरांसाठी योग्य उपाय?'
            },
            answer: {
                en: 'Lumpy skin disease is a viral disease that affects feed intake and reduces milk production.',
                tm: 'பெரியம்மை நோய் என்பது ஒரு வைரஸ் நோயாகும், இது தீவனம் உட்கொள்ளுவதை பாதிக்கிறது மற்றும் பால் உற்பத்தியைக் குறைக்கிறது.',
                mr: 'ढेकूळ त्वचा रोग हा एक विषाणूजन्य रोग आहे जो फीडचे सेवन प्रभावित करतो आणि दुधाचे उत्पादन कमी करतो.'
            },
        },
        {
            id: 4, image_url: Foot,
            diesase: {
                en: 'Foot and mouth disease',
                tm: 'கோமாரி நோய் ',
                mr: 'पाय आणि तोंड रोग'
            },
            question: {
                en: 'Treat animals with ethnoveterinary herbal solutions?',
                tm: 'கால்நடைகளுக்கு பாரம்பரிய மூலிகை மருந்துகள்  மூலம் சிகிச்சை அளியுங்கள்? ',
                mr: 'प्"प्राण्यांवर इथोव्हेटेरिनरी हर्बल द्रावणाने उपचार करा?'
            },
            answer: {
                en: 'FMD causes fever, and wounds in the mouth and hook, reduces appetite, and severely affects productivity.',
                tm: 'கோமாரி நோய் காய்ச்சலை ஏற்படுத்துகிறது, வாய் மற்றும் குளம்புகளில் புண்கள் , பசியைக் குறைக்கிறது மற்றும் உற்பத்தித்திறனை கடுமையாக பாதிக்கிறது.',
                mr: 'FMD मुळे ताप येतो, तोंडात आणि खुरात जखमा होतात, भूक कमी होते आणि उत्पादकतेवर गंभीर परिणाम होतो.'
            },
        },
    ];

    return (
        <>
        <div className="container-fluid mt-2"
            style={{ backgroundColor: "#F6F7FF" }}>
            <Container>
                <div
                    className="treatment_div_cls mb-3 mt-custom"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div>
                        {t("Common Diseases")}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            color: "#000",
                            position: "relative",
                            borderRadius: "5px",
                            padding: "10px 20px",
                            cursor: "pointer",
                            fontSize: "18px",
                            transition: "color 0.3s ease",
                            color: isHovered ? "rgb(5 5 5)" : "",
                        }}
                        onClick={handleViewMore}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                    >
                        {t('View More')}
                        <svg
                            style={{
                                marginLeft: "8px", // Space between the text and the arrow
                                verticalAlign: "middle",
                                fill: isHovered ? "rgb(35, 44, 99)" : "rgb(35, 44, 99)",
                                height: "22px",
                                transition: "fill 0.3s ease",
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                        >
                            <path d="M10 17l5-5-5-5v10z" />
                        </svg>
                    </div>
                </div>

                <Slider {...settings} className="slider-arrow-custom-style-for-related-products">
                    {products?.slice(0, 10)?.map((product, index) => (
                        <div key={index} className="consultation-diseases-card cursor-pointer">
                            <img
                                src={product.image_url}
                                className="consult-common-disease-img rounded"
                                alt={`Product ${index + 1}`}
                            />
                            <div className="consulattion-text ">
                                <div className="limited-text-in-2-line" style={{ color: '#232c63', fontSize: '17px', margin: "1rem 0", fontWeight: '500', textAlign: 'center' }}>
                                    {product.diesase[i18n.language]}
                                </div>                                {/* <h5 style={{ color: '', textAlign: 'left', fontSize: '18px', letterSpacing: '0.6px', margin: "1rem 0" }}>{product.diesase[i18n.language]}</h5> */}
                                <div>
                                    <div className="limited-question-text" style={{ color: 'black', fontSize: '15px' }}>
                                        {product.question[i18n.language]}
                                    </div>
                                    <div className="limited-text-in-3-line" style={{ color: '#232C63', fontSize: '14px' }}>
                                        {product.answer[i18n.language]}
                                    </div>
                                </div>
                            </div>
                            <div className="CommonDieasesscustom-buttons">
                                <button type="button" className="CommonDieasesschat-button">
                                    <a
                                        href={`https://api.whatsapp.com/send/?phone=916383717150&text=${t('HOME.whatsApp')}&type=phone_number&app_absent=0`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >

                                        <PiChatText style={{ color: 'rgba(35, 44, 99, 1)' }} /> <span style={{ ...textStyle, color: 'rgba(35, 44, 99, 1)' }}>{t("Chat")}</span>
                                    </a>
                                </button>
                                <button type="button" className='CommonDieasesscall-button'>
                                    <IoCallOutline />
                                    <span
                                    style={textStyle}
                                     onClick={() => {
                                        const phoneNumber = DecryptFunction(sessionStorage.getItem('pilot_no'));
                                        LogEvent('Floating_Call_Button_Clicked');
                                        window.location.href = `tel:${phoneNumber}`;
                                    }}>{t("Call")} </span>
                                </button>

                            </div>
                        </div>
                    ))}
                </Slider>
            </Container>
        </div>

        {/* <Container style={{background: 'linear-gradient(94.66deg, #081271 46.3%, #FFB9C7 90.24%)' }}>
            <h1>hello</h1>
        </Container> */}
        </>
    );
};

export default CommonDiseasesPage;

// import React, { useEffect, useState } from "react";
// import { Button, Col, Container, Row } from "react-bootstrap";
// import { useTranslation } from 'react-i18next';
// import { useNavigate, useSearchParams } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import cartActions from "../../Redux/cart/actions";
// import userActions from "../../Redux/user/actions";
// import commonActions from "../../Redux/common/actions";
// import couponActions from "../../Redux/coupon/actions";
// import checkoutActions from "../../Redux/checkout/actions";
// import homeActions from '../../Redux/Home/action';
// import { FaCirclePlus } from 'react-icons/fa6';
// import { MdOutlineCurrencyRupee } from "react-icons/md";
// import { DecryptFunction, EncryptFunction } from "../../utils/cryptoFunction";
// import { priceRoundOff } from "../../utils/validation";
// import StepperComponent from "./stepper";
// import moment from "moment-timezone";
// import { LogEvent } from "../../utils/firebase";

// // import SellerSelectAddressModal from "../SellerProfile/SellerSelectAddressModal";
// import DefaultAddressChangeModal from "../modal/defaultaddresschangeModal";

// export default function CheckoutIndex() {

//     const { t } = useTranslation();

//     const navigate = useNavigate();
//     const dispatch = useDispatch();
//     const [searchParams] = useSearchParams();
//     const id = searchParams.get('id');

//     const { cartProducts } = useSelector(state => state.cartReducer);
//     const { userAddress } = useSelector(state => state.userReducer);

//     const { available_Coupons } = useSelector(state => state.couponReducer);

//     const [selleraddress, setSelleraddress] = useState();

//     const { deliveryType, checkoutValues, paymentType } = useSelector(state => state.checkoutReducer);
//     // console.log("1234",deliveryType)
//     // console.log("checkoutValues", checkoutValues)

//     const [showAddressModal, setShowAddressModal] = useState(false);

//     const [codAgreeValue, setCodAgreeValue] = useState({ value1: false, value2: false });
//     const [checkoutErr, setCheckoutErr] = useState('');
//     const [currentStep, setCurrentStep] = useState(0);
//     const [priceDetails, setPriceDetails] = useState({
//         amount: 0, discount: 0, coupon_discount: 0, total: 0
//     });

//     const handleAddress = (Address) => {
//         Address[`default_address`] = 1
//         setSelleraddress(Address);
//         const id = DecryptFunction(localStorage.getItem('auth_user'));
//         Address['user_id'] = id;
//         // dispatch({
//         //     type: commonActions.GET_ADDRESS_COORDINATES,
//         //     payload: Address,
//         // });
//         dispatch({ type: userActions.UPDATE_USER_ADDRESS, payload: { id: Address?.id, data: Address } })

//         setShowAddressModal(false)

//     };

//     let [copiedCoupon, setCopiedCoupon] = useState({
//         code: null, index: null
//     });

//     useEffect(() => {

//         console.log('checkout values check', checkoutValues)

//         if (id) {
//             dispatch({ type: cartActions.GET_CART_PRODUCT_LIST, payload: { id: id } });
//         } else if (localStorage.getItem('token') && localStorage.getItem('auth_user')) {
//             navigate(`/checkout?id=${localStorage.getItem('auth_user')}`);
//             dispatch({
//                 type: cartActions.GET_CART_PRODUCT_LIST,
//                 payload: { id: localStorage.getItem('auth_user') }
//             });
//         };
//         //eslint-disable-next-line
//     }, [id]);

//     useEffect(() => {
//         console.log('checkout values check2', checkoutValues)
//         dispatch({ type: checkoutActions.GET_DELIVERY_TYPE_LIST });
//         dispatch({ type: homeActions.GET_HOME_CONTENTS });
//         //eslint-disable-next-line
//     }, []);

//     useEffect(() => {
//         console.log('checkout values check3', checkoutValues)
//         if (localStorage.getItem('auth_user')) {
//             const id = DecryptFunction(localStorage.getItem('auth_user'));
//             if (id) {
//                 dispatch({ type: userActions.GET_USER_ADDRESS, payload: { id: id } });
//                 dispatch({
//                     type: homeActions.GET_USER_PROFILE,
//                     payload: { id: id },
//                 });
//             }
//             dispatch({
//                 type: couponActions.GET_COUPON_BY_MOBILENUMBER,
//                 payload: localStorage.getItem('auth_user_no')
//             });
//         }
//         //eslint-disable-next-line
//     }, []);

//     useEffect(() => {
//         // console.log('checkout values check', checkoutValues)
//         if ((cartProducts && cartProducts?.order_items?.length > 0) && userAddress?.length > 0 && deliveryType?.length > 0) {
//             let array = { amount: 0, discount: 0, total: 0 }
//             //eslint-disable-next-line
//             cartProducts?.order_items?.map((val) => {
//                 let product_rate = parseFloat(val?.rate) * parseInt(val?.quantity)
//                 array['amount'] = parseFloat(array['amount']) + parseFloat(product_rate);
//                 let product_dis = parseFloat(val?.discount) * parseInt(val?.quantity)
//                 array['discount'] = parseFloat(array['discount']) + parseFloat(product_dis);
//             });
//             array['total'] = array['amount'] - array['discount'];
//             setPriceDetails(array);
//             dispatch({ type: checkoutActions.CALCUATE_GST });
//         };
//         //eslint-disable-next-line
//     }, [cartProducts, userAddress, deliveryType]);

//     const handleAddAddress = () => {
//         LogEvent('Checkout_DeliveryAddress_Add_Clicked')
//         dispatch({ type: commonActions.SET_SHOW_USER_ADDRESS_MODAL, payload: true });
//     };

//     const handleShowAddressModal = () => {
//         setShowAddressModal(true);
//     };

//     const handleNext = () => {
//         LogEvent('Checkout_Step1_Continue_Clicked')
//         window.scrollTo(0, 0);
//         setCurrentStep((prevStep) => prevStep + 1);
//     };

//     // const handleChangeValues = (e, type) => {
//     //     if (type === 'deliveryAddress' && e.target.value) {
//     //         LogEvent('Checkout_DeliveryAddress_Changed')
//     //         dispatch({
//     //             type: checkoutActions.CHECKOUT_VALUES,
//     //             payload: { ...checkoutValues, DeliveryAddress: JSON.parse(e.target.value) }
//     //         });
//     //         dispatch({
//     //             type: checkoutActions.CALCULATE_SHIPPING, payload: {
//     //                 "weight_kg": checkoutValues?.wight_in_kg,
//     //                 "state": JSON.parse(e.target.value)?.state,
//     //                 "provider_id": checkoutValues?.DeliveryType?.id,
//     //                 "order_value": checkoutValues?.total,
//     //                 "shipping_type": checkoutValues?.DeliveryType?.shipping_type
//     //             }
//     //         });
//     //     } else if (type === 'deliveryType' && e.target.value) {
//     //         LogEvent('Checkout_DeliveryType_Changed')
//     //         dispatch({
//     //             type: checkoutActions.CHECKOUT_VALUES,
//     //             payload: { ...checkoutValues, DeliveryType: JSON.parse(e.target.value) }
//     //         });
//     //         dispatch({
//     //             type: checkoutActions.CALCULATE_SHIPPING, payload: {
//     //                 "weight_kg": checkoutValues?.wight_in_kg,
//     //                 "state": checkoutValues?.DeliveryAddress?.state,
//     //                 "provider_id": JSON.parse(e.target.value)?.id,
//     //                 "order_value": checkoutValues?.total,
//     //                 "shipping_type": checkoutValues?.DeliveryType?.shipping_type

//     //             }
//     //         });
//     //     }
//     // };

//     const handleChangeValues = (e, type) => {
//         if (type === 'deliveryAddress' && e.target.value) {
//             LogEvent('Checkout_DeliveryAddress_Changed')
//             dispatch({
//                 type: checkoutActions.CHECKOUT_VALUES,
//                 payload: { ...checkoutValues, DeliveryAddress: JSON.parse(e.target.value) }
//             });
//             dispatch({
//                 type: checkoutActions.CALCULATE_SHIPPING, payload: {
//                     "weight_kg": checkoutValues?.wight_in_kg,
//                     "state": JSON.parse(e.target.value)?.state,
//                     "provider_id": checkoutValues?.DeliveryType?.id,
//                     "order_value": checkoutValues?.total,
//                     "shipping_type": checkoutValues?.DeliveryType?.shipping_type
//                 }
//             });
//             // window.location.reload();  // Reload the page if needed
//         } else if (type === 'deliveryType' && e.target.value) {
//             LogEvent('Checkout_DeliveryType_Changed')
//             dispatch({
//                 type: checkoutActions.CHECKOUT_VALUES,
//                 payload: { ...checkoutValues, DeliveryType: JSON.parse(e.target.value) }
//             });
//             dispatch({
//                 type: checkoutActions.CALCULATE_SHIPPING, payload: {
//                     "weight_kg": checkoutValues?.wight_in_kg,
//                     "state": checkoutValues?.DeliveryAddress?.state,
//                     "provider_id": JSON.parse(e.target.value)?.id,
//                     "order_value": checkoutValues?.total,
//                     "shipping_type": JSON.parse(e.target.value)?.shipping_type  // Corrected this line
//                 }
//             });
//             // window.location.reload();  // Reload the page if needed
//         }
//     };

//     const handlePaymentChange = (value) => {
//         LogEvent('Checkout_PaymentType_Changed')
//         dispatch({
//             type: checkoutActions.CHECKOUT_VALUES,
//             payload: { ...checkoutValues, PaymentType: value }
//         });
//         setCheckoutErr('');
//         setCodAgreeValue({ value1: false, value2: false });
//         if (value === 0) {
//             dispatch({
//                 type: checkoutActions.CALCULATE_SHIPPING, payload: {
//                     "weight_kg": checkoutValues?.wight_in_kg,
//                     "state": checkoutValues?.DeliveryAddress?.state,
//                     "provider_id": checkoutValues?.DeliveryType?.id,
//                     "order_value": checkoutValues?.total,
//                     "shipping_type": "COD"

//                     // "shipping_type": checkoutValues?.DeliveryType?.shipping_type

//                 }
//             });
//         } else {
//             dispatch({
//                 type: checkoutActions.CALCULATE_SHIPPING, payload: {
//                     "weight_kg": checkoutValues?.wight_in_kg,
//                     "state": checkoutValues?.DeliveryAddress?.state,
//                     "provider_id": checkoutValues?.DeliveryType?.id,
//                     "order_value": checkoutValues?.total,
//                     "shipping_type": checkoutValues?.DeliveryType?.shipping_type

//                 }
//             });
//         };
//     };

//     const handleCouponApply = (code, index) => {
//         LogEvent('Checkout_Coupon_Applied')
//         setCopiedCoupon(copiedCoupon = { code: code, index: index, applied_Coupon: code });
//         let couponValues = available_Coupons?.find((val) => val?.code === copiedCoupon?.code);
//         let pricedetails = { ...priceDetails };
//         if (couponValues) {
//             let copied = { ...copiedCoupon };
//             copied["Error"] = '';
//             let coupon_discount = 0, finalTotal;
//             if (couponValues?.discount_type === 1) {
//                 coupon_discount = priceRoundOff(((parseFloat(priceDetails?.amount) - parseFloat(priceDetails?.discount)) * parseFloat(couponValues?.discount)) / 100);
//             } else {
//                 coupon_discount = priceRoundOff(couponValues?.discount);
//             };
//             finalTotal = priceRoundOff(parseFloat(pricedetails?.total) - parseFloat(coupon_discount));
//             pricedetails['coupon_discount'] = coupon_discount;
//             pricedetails['total'] = finalTotal;
//             setPriceDetails(pricedetails);
//             dispatch({ type: checkoutActions.CHECKOUT_VALUES, payload: { ...checkoutValues, coupon_discount: coupon_discount, applied_Coupon: couponValues } })
//             dispatch({ type: checkoutActions.CALCUATE_GST });
//         } else {
//             let copied = { ...copiedCoupon };
//             copied["Error"] = "Invalid Coupon code";
//             setCopiedCoupon(copied);
//         }
//     };

//     const handledeliveryNotes = (e) => {
//         dispatch({
//             type: checkoutActions.CHECKOUT_VALUES,
//             payload: { ...checkoutValues, DeliveryNotes: e.target.value }
//         });
//     };

//     const handleConfirmOrder = (e) => {
//         e.preventDefault();
//         LogEvent('Checkout_PlaceOrder_Clicked')
//         if (checkoutValues?.PaymentType === 0 && (codAgreeValue?.value1 === false || codAgreeValue?.value2 === false)) {
//             setCheckoutErr('Please agree the terms');
//         } else {
//             setCheckoutErr('');
//             let userid = DecryptFunction(localStorage.getItem('auth_user'));
//             if (userid) {
//                 dispatch({
//                     type: checkoutActions.CREATE_NEW_ORDER, payload: {
//                         data: {
//                             "id": cartProducts?.id,
//                             "user_id": userid,
//                             "subtotal": parseFloat(checkoutValues?.product_amount),
//                             // "subtotal": parseFloat(checkoutValues?.total) - parseFloat(checkoutValues?.gst_amount),
//                             "vat": 0,
//                             "shipping_charges": parseFloat(checkoutValues?.shippingCharge || 0),
//                             "total_amount": checkoutValues?.total,
//                             "location_id": cartProducts?.location_id,
//                             "discount": checkoutValues?.discount,
//                             "coupon_discount": checkoutValues?.coupon_discount,
//                             "notes": checkoutValues?.DeliveryNotes,
//                             "order_date": moment().format("YYYY-MM-DD"),
//                             "payment_type": checkoutValues?.PaymentType,
//                             "transporter_id": checkoutValues?.DeliveryType?.id,
//                             "order_type": 0,
//                             "order_source":2
//                         },
//                         navigate: navigate
//                     }
//                 });
//             };
//         }
//     };

//     const handleViewProductDetails = (id) => {
//         LogEvent('Checkout_Product_Clicked')
//         const encrypt = EncryptFunction(id);
//         if (encrypt) {
//             navigate(`/product-details?id=${encrypt}`);
//             // window.location.reload();
//         }
//     };

//     return (
//         <Container className="mb-5">
//             <Row className="row-padding-remover">
//                 <Col md={12} className="my-4">
//                     <StepperComponent currentStep={currentStep} />
//                 </Col>
//                 <Col lg={7} md={7}>
//                     <div className="checkout-col1">
//                         {currentStep === 0 &&
//                             <Row className="mb-2 row-padding-remover">
//                                 <Col md={12} sm={12} className="mb-2">
//                                     <label className="form-label checkout-step1-label">{t("Delivery Type")}</label>
//                                 </Col>
//                                 <Col md={12}>
//                                     {localStorage.getItem('cattle_type')?.toLowerCase() === 'feed' ? (
//                                         deliveryType?.filter((val) => val?.shipping_type?.toLowerCase() === 'hyperlocal delivery')?.map((val, i) =>
//                                             <div className="d-flex align-items-start mb-3" key={i}>
//                                                 <input
//                                                     type="radio"
//                                                     id="deliverytype"
//                                                     name="Deliery_Type"
//                                                     className="mt-1 checkout-radio-btn"
//                                                     value={JSON.stringify(val)}
//                                                     defaultChecked={(!checkoutValues?.DeliveryType) ? true : ((checkoutValues?.DeliveryType && JSON.stringify(checkoutValues?.DeliveryType) === JSON.stringify(val)) ? true : false)}
//                                                     onChange={(e) => handleChangeValues(e, 'deliveryType')}
//                                                 />
//                                                 <label
//                                                     htmlFor="deliverytype"
//                                                     className={`ps-3 checkout-delivery-type-label-values ${((checkoutValues?.DeliveryType && JSON.stringify(checkoutValues?.DeliveryType) === JSON.stringify(val)) || (!checkoutValues?.DeliveryType && val?.shipping_type === 'Hyperlocal')) ? 'elected-delivery-type-label-active' : ''}`}
//                                                 >
//                                                     {val?.shipping_type} [{val?.delivery_range}]
//                                                 </label>
//                                             </div>
//                                         )
//                                     ) : (
//                                         deliveryType?.filter((val) => val?.shipping_type === 'Express' || val?.shipping_type === 'Standard')?.map((val, i) =>
//                                             <div className="d-flex align-items-start mb-3" key={i}>
//                                                 <input
//                                                     type="radio"
//                                                     id="deliverytype"
//                                                     name="Deliery_Type"
//                                                     className="mt-1 checkout-radio-btn"
//                                                     value={JSON.stringify(val)}
//                                                     defaultChecked={((checkoutValues?.DeliveryType && JSON.stringify(checkoutValues?.DeliveryType) === JSON.stringify(val)) || (!checkoutValues?.DeliveryType && val?.shipping_type === 'Express')) ? true : false}
//                                                     onChange={(e) => handleChangeValues(e, 'deliveryType')}
//                                                 />
//                                                 <label
//                                                     htmlFor="deliverytype"
//                                                     className={`ps-3 checkout-delivery-type-label-values ${((checkoutValues?.DeliveryType && JSON.stringify(checkoutValues?.DeliveryType) === JSON.stringify(val)) || (!checkoutValues?.DeliveryType && val?.shipping_type === 'Express')) ? 'elected-delivery-type-label-active' : ''}`}
//                                                 >
//                                                     {val?.shipping_type} [{val?.delivery_range}]
//                                                 </label>
//                                             </div>
//                                         )
//                                     )}
//                                     <div className="checkout-page-hr-cls" />
//                                 </Col>
//                                 {/* <Col md={12}>
//     {localStorage.getItem('cattle_type').toLowerCase() === 'feed' ? (
//         deliveryType?.filter((val) => val?.shipping_type.toLowerCase() === 'hyperlocal delivery')?.map((val, i) => (
//             <div className="d-flex align-items-start mb-3" key={i}>
//                 <input
//                     type="radio"
//                     id={`deliverytype-${i}`}
//                     name="Delivery_Type"
//                     className="mt-1 checkout-radio-btn"
//                     value={JSON.stringify(val)}
//                     defaultChecked={(!checkoutValues?.DeliveryType) ? true : ((checkoutValues?.DeliveryType && JSON.stringify(checkoutValues?.DeliveryType) === JSON.stringify(val)) ? true : false)}
//                     onChange={(e) => handleChangeValues(e, 'deliveryType')}
//                 />
//                 <label
//                     htmlFor={`deliverytype-${i}`}
//                     className={`ps-3 checkout-delivery-type-label-values ${((checkoutValues?.DeliveryType && JSON.stringify(checkoutValues?.DeliveryType) === JSON.stringify(val)) || (!checkoutValues?.DeliveryType && val?.shipping_type === 'Hyperlocal')) ? 'elected-delivery-type-label-active' : ''}`}
//                 >
//                     {val?.shipping_type} [{val?.delivery_range}]
//                 </label>
//             </div>
//         ))
//     ) : (
//         deliveryType?.filter((val) => val?.shipping_type === 'Express' || val?.shipping_type === 'Standard')?.map((val, i) => (
//             <div className="d-flex align-items-start mb-3" key={i}>
//                 <input
//                     type="radio"
//                     id={`deliverytype-${i}`}
//                     name="Delivery_Type"
//                     className="mt-1 checkout-radio-btn"
//                     value={JSON.stringify(val)}
//                     defaultChecked={((checkoutValues?.DeliveryType && JSON.stringify(checkoutValues?.DeliveryType) === JSON.stringify(val)) || (!checkoutValues?.DeliveryType && val?.shipping_type === 'Express')) ? true : false}
//                     onChange={(e) => handleChangeValues(e, 'deliveryType')}
//                 />
//                 <label
//                     htmlFor={`deliverytype-${i}`}
//                     className={`ps-3 checkout-delivery-type-label-values ${((checkoutValues?.DeliveryType && JSON.stringify(checkoutValues?.DeliveryType) === JSON.stringify(val)) || (!checkoutValues?.DeliveryType && val?.shipping_type === 'Express')) ? 'elected-delivery-type-label-active' : ''}`}
//                 >
//                     {val?.shipping_type} [{val?.delivery_range}]
//                 </label>
//             </div>
//         ))
//     )}
//     <div className="checkout-page-hr-cls" />
// </Col> */}
//                                 {/* <Col md={12}>
//     {(() => {
//         // Filter delivery types
//         const hyperLocalTypes = deliveryType?.filter(val => val?.shipping_type === 'Hyperlocal Delivery');
//         const otherTypes = deliveryType?.filter(val => val?.shipping_type === 'Express' || val?.shipping_type === 'Standard');

//         // Determine which types to display
//         const typesToDisplay = hyperLocalTypes?.length > 0 ? hyperLocalTypes : otherTypes;

//         return typesToDisplay?.map((val, i) => (
//             <div className="d-flex align-items-start mb-3" key={i}>
//                 <input
//                     type="radio"
//                     id={`deliverytype-${i}`}
//                     name="Delivery_Type"
//                     className="mt-1 checkout-radio-btn"
//                     value={JSON.stringify(val)}
//                     defaultChecked={hyperLocalTypes.length > 0 && i === 0}
//                     onChange={(e) => handleChangeValues(e, 'deliveryType')}
//                 />
//                 <label
//                     htmlFor={`deliverytype-${i}`}
//                     className={`ps-3 checkout-delivery-type-label-values ${((checkoutValues?.DeliveryType && JSON.stringify(checkoutValues?.DeliveryType) === JSON.stringify(val))) ? 'selected-delivery-type-label-active' : ''}`}
//                 >
//                     {val?.shipping_type} [{val?.delivery_range}]
//                 </label>
//             </div>
//         ));
//     })()}
//     <div className="checkout-page-hr-cls" />
// </Col> */}

//                                 <Col md={9} sm={12}>
//                                     <label className="form-label checkout-step1-label">{t("Delivery Address")}</label>
//                                 </Col>
//                                 <Col md={3} sm={12} className="d-flex justify-content-end">
//                                     <Col md={3} sm={12} className="d-flex justify-content-end">
//                                         <div
//                                             className="change-address-link d-flex justify-content-end"
//                                             onClick={handleShowAddressModal}
//                                         >
//                                             {selleraddress ? t("Change Address ") : t("Change Address ")}
//                                         </div>
//                                     </Col>

//                                 </Col>

//                                 <Col md={12}>

//                                     {userAddress
//                                         .filter(val => val?.default_address === 1) // Filter addresses where default_address is 1
//                                         .map((val, i) =>
//                                             <div className="d-flex align-items-start mb-3" key={i}>
//                                                 <input
//                                                     type="radio"
//                                                     id={`address${i}`} // Use a unique id for each input
//                                                     name="User_Address"
//                                                     className="mt-1 checkout-radio-btn"
//                                                     value={JSON.stringify(val)}
//                                                     defaultChecked={((checkoutValues?.DeliveryAddress && JSON.stringify(checkoutValues?.DeliveryAddress) === JSON.stringify(val)) || (!checkoutValues?.DeliveryAddress && i === 0)) ? true : false}
//                                                     onChange={(e) => handleChangeValues(e, 'deliveryAddress')}
//                                                 />
//                                                 <label
//                                                     htmlFor={`address${i}`} // Match the input's id
//                                                     className="ps-3 checkout-delivery-address-font"
//                                                 >
//                                                     {`${val?.address_line1}, ${val?.address_line2}, ${val?.address_line3},`} <br />
//                                                     {`${val?.city}- ${val?.pincode}`} <br />
//                                                     {`${val?.state}, ${val?.country}`}
//                                                 </label>
//                                                 <br />
//                                             </div>
//                                         )
//                                     }

//                                     <div className="checkout-page-hr-cls" />
//                                     <DefaultAddressChangeModal
//                                         showModal={showAddressModal}
//                                         closeModal={() => setShowAddressModal(false)}
//                                         handleAddress={handleAddress}
//                                     />
//                                 </Col>
//                                 <Col md={12}>
//                                     <label className="form-label checkout-step1-label">{t("My Coupons")}</label>
//                                     {available_Coupons?.length > 0 ?
//                                         <div className="checkout-coupon-code-maind-cls">
//                                             {available_Coupons?.map((val, i) =>
//                                                 <div className="discount-code" key={i}>
//                                                     <div className="offer-container">
//                                                         <span className="offer-text">{val?.discount_type === 1 ? `${val?.discount}% offer on this order` : `₹${val?.discount} offer on this order`}</span>
//                                                         <div className="code-container">
//                                                             <span className="code-text">CODE : {val?.code}</span>
//                                                             {i !== copiedCoupon?.index ? (
//                                                                 <div className="apply-button" onClick={() => handleCouponApply(val?.code, i, val)}>
//                                                                     {t("TAP TO APPLY")}
//                                                                 </div>
//                                                             ) : (
//                                                                 <div className="applied-text">{t("APPLIED")}</div>
//                                                             )}
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             )}
//                                         </div> :
//                                         <div className="text-center mt-3">
//                                             <h6>{t("No Coupons")}</h6>
//                                         </div>
//                                     }
//                                     <small className="text-danger fw-600 ms-1">{copiedCoupon?.Error}</small>
//                                     <div className="checkout-page-hr-cls" />
//                                 </Col>
//                                 <Col md={12}>
//                                     <label className="form-label checkout-step1-label mb-3">{t("Price Details")}</label>
//                                     <dl className="d-flex justify-content-between my-2">
//                                         <dt className="checkout-page-price-details-label-1">{t("Subtotal")}</dt>
//                                         <dd className="cart-product-subtotal"><MdOutlineCurrencyRupee /> {parseFloat(checkoutValues?.product_amount || priceDetails?.amount)?.toFixed(2)}</dd>
//                                     </dl>
//                                     <dl className="d-flex justify-content-between my-2">
//                                         <dt className="checkout-page-price-details-label-1">{t("Discount")}</dt>
//                                         <dd className="cart-product-dis-dd">- <MdOutlineCurrencyRupee /> {parseFloat(checkoutValues?.discount || priceDetails?.discount)?.toFixed(2)}</dd>
//                                     </dl>
//                                     {(checkoutValues?.coupon_discount || priceDetails?.coupon_discount) &&
//                                         <dl className="d-flex justify-content-between my-2">
//                                             <dt className="checkout-page-price-details-label-1">{t("Coupon Discount")}</dt>
//                                             <dd className="cart-product-dis-dd">- <MdOutlineCurrencyRupee /> {parseFloat(checkoutValues?.coupon_discount || priceDetails?.coupon_discount)?.toFixed(2)}</dd>
//                                         </dl>
//                                     }
//                                     <dl className="d-flex justify-content-between my-2">
//                                         <dt className="checkout-page-price-details-label-1">{t("Shipping Charges")}</dt>
//                                         <dd className="cart-product-subtotal"><MdOutlineCurrencyRupee /> {parseFloat(checkoutValues?.shippingCharge || 0)?.toFixed(2)}</dd>
//                                     </dl>
//                                     <div className="checkout-page-hr-cls" />
//                                     <dl className="d-flex justify-content-between">
//                                         <dt>{t("Total price")}</dt>
//                                         <dd className="cart-product-final-price-dd">
//                                             <strong><MdOutlineCurrencyRupee /> {parseFloat(checkoutValues?.total || priceDetails?.total)?.toFixed(2)}</strong>
//                                         </dd>
//                                     </dl>
//                                 </Col>
//                             </Row>
//                         }
//                         {currentStep === 1 &&
//                             <Row className="row-padding-remover">
//                                 <Col md={12}>
//                                     <label className="form-label checkout-step1-label mb-3">{t("Price Details")}</label>
//                                     <dl className="d-flex justify-content-between my-2">
//                                         <dt className="checkout-page-price-details-label-1">{t("Subtotal")}</dt>
//                                         <dd className="cart-product-subtotal"><MdOutlineCurrencyRupee /> {parseFloat(checkoutValues?.product_amount || priceDetails?.amount)?.toFixed(2)}</dd>
//                                     </dl>
//                                     <dl className="d-flex justify-content-between my-2">
//                                         <dt className="checkout-page-price-details-label-1">{t("Discount")}</dt>
//                                         <dd className="cart-product-dis-dd">- <MdOutlineCurrencyRupee /> {parseFloat(checkoutValues?.discount || priceDetails?.discount)?.toFixed(2)}</dd>
//                                     </dl>
//                                     {(checkoutValues?.coupon_discount || priceDetails?.coupon_discount) &&
//                                         <dl className="d-flex justify-content-between my-2">
//                                             <dt className="checkout-page-price-details-label-1">{t("Coupon Discount")}</dt>
//                                             <dd className="cart-product-dis-dd">- <MdOutlineCurrencyRupee /> {parseFloat(checkoutValues?.coupon_discount || priceDetails?.coupon_discount)?.toFixed(2)}</dd>
//                                         </dl>
//                                     }
//                                     <dl className="d-flex justify-content-between my-2">
//                                         <dt className="checkout-page-price-details-label-1">{t("Shipping Charges")}</dt>
//                                         <dd className="cart-product-subtotal"><MdOutlineCurrencyRupee /> {parseFloat(checkoutValues?.shippingCharge || 0)?.toFixed(2)}</dd>
//                                     </dl>
//                                     <div className="checkout-page-hr-cls" />
//                                     <dl className="d-flex justify-content-between mt-3 mb-1">
//                                         <dt>{t("Total price")}</dt>
//                                         <dd className="cart-product-final-price-dd">
//                                             <strong><MdOutlineCurrencyRupee /> {parseFloat(checkoutValues?.total || priceDetails?.total)?.toFixed(2)}</strong>
//                                         </dd>
//                                     </dl>
//                                     <div className="checkout-page-hr-cls" />
//                                 </Col>
//                                 {/* <Col lg={12} className="mt-1">
//                                     <label className="form-label checkout-step1-label">{t("Payment")}</label>
//                                     {paymentType?.filter((val) => {
//                                         const total = parseInt(checkoutValues?.total);
//                                         if (total < 500 || total > 1500) {
//                                             return val?.title !== 'COD';
//                                         } else {
//                                             return true;
//                                         }
//                                     })?.map((val, i) =>
//                                         <div className="d-flex align-items-start mb-2 checkout-payment-type" key={i}>
//                                             <input
//                                                 type="radio"
//                                                 id="paymenttype"
//                                                 name="Payment_Type"
//                                                 className="mt-1 checkout-radio-btn"
//                                                 value={val.title === 'COD' ? 0 : 1}
//                                                 defaultChecked={(checkoutValues?.PaymentType === 1 && val.title !== 'COD') ? true : false}
//                                                 onChange={() => handlePaymentChange(val.title === 'COD' ? 0 : 1)}
//                                             />
//                                             <label
//                                                 htmlFor="paymenttype"
//                                                 className="ps-3 checkout-delivery-address-font"
//                                             >
//                                                 <img src={val?.img_url} alt="" className="checkout-payment-type-img" />{val?.title}
//                                             </label>
//                                         </div>
//                                     )}
//                                     <div className="checkout-page-hr-cls my-3" />
//                                 </Col> */}
//                                 <Col lg={12} className="mt-1">
//                                     <label className="form-label checkout-step1-label">{t("Payment")}</label>

//                                     {paymentType?.filter((val) => {
//                                         const total = parseInt(checkoutValues?.total);
//                                         const cattleType = localStorage.getItem('cattle_type')?.toLowerCase();

//                                         // Do not show COD if cattle_type is 'feed'
//                                         if (cattleType === 'feed') {
//                                             return val?.title !== 'COD';
//                                         }

//                                         // For other cattle types, apply the total amount condition for COD
//                                         if (total < 500 || total > 1500) {
//                                             return val?.title !== 'COD';
//                                         } else {
//                                             return true;
//                                         }
//                                     })?.reverse()?.map((val, i) =>
//                                         <div className="d-flex align-items-start mb-2 checkout-payment-type" key={i}>
//                                             <input
//                                                 type="radio"
//                                                 id={`paymenttype-${i}`}
//                                                 name="Payment_Type"
//                                                 className="mt-1 checkout-radio-btn"
//                                                 value={val?.title === 'COD' ? 0 : 1}
//                                                 // value={val.title === 1}
//                                                 // defaultChecked={false}
//                                                 defaultChecked={(checkoutValues?.PaymentType === 1 && val?.title !== 'COD') ? true : false}
//                                                 onChange={() => handlePaymentChange(val?.title === 'COD' ? 0 : 1)}
//                                                 // onChange={() => handlePaymentChange(val.title === 1)}
//                                             />
//                                             {console.log(checkoutValues, 'values')}
//                                             <label
//                                                 htmlFor={`paymenttype-${i}`}
//                                                 className="ps-3 checkout-delivery-address-font"
//                                             >
//                                                 <img src={val?.img_url} alt="" className="checkout-payment-type-img" />{val?.title}
//                                             </label>
//                                         </div>
//                                     )}
//                                     <div className="checkout-page-hr-cls my-3" />
//                                 </Col>

//                                 <Col md={12}>
//                                     <label className="form-label checkout-step1-label">{t("Delivery Notes")}</label>
//                                     <textarea className="form-control" onChange={handledeliveryNotes} />
//                                 </Col>
//                                 {(checkoutValues?.PaymentType === 0) &&
//                                     <Col md={12} className="mt-4">
//                                         <div className="d-flex">
//                                             <input
//                                                 type="checkbox"
//                                                 className="me-3 checkout-iagree-input"
//                                                 onClick={(e) => setCodAgreeValue({ ...codAgreeValue, value1: e.target.checked })}
//                                                 value={codAgreeValue?.value1}
//                                             />
//                                             <div className="checkout-policy-checkbox-div">{t("I agree that i will be billed the amount of shipping charges in the next order, If the order is rejected at the time of delivery")}</div>
//                                         </div>
//                                         <div className="d-flex mt-2">
//                                             <input
//                                                 type="checkbox"
//                                                 className="me-3 checkout-iagree-input"
//                                                 onClick={(e) => setCodAgreeValue({ ...codAgreeValue, value2: e.target.checked })}
//                                                 value={codAgreeValue?.value2}
//                                             />
//                                             <div className="checkout-policy-checkbox-div">{t("I agree that I will pay the cash on delivery and I will not reject the products for reasons other than the damage in packing")}</div>
//                                         </div>
//                                     </Col>
//                                 }
//                                 {(checkoutErr && (!codAgreeValue?.value1 || !codAgreeValue?.value2)) &&
//                                     <p className="text-danger my-2">{checkoutErr}</p>
//                                 }
//                             </Row>
//                         }
//                     </div>
//                     <div className="checkout-page-btns-div">
//                         <Button
//                             className="checkout-page-cancel-btn"
//                             onClick={() => {
//                                 LogEvent('Checkout_Cancel_Clicked')
//                                 navigate('/cart')
//                             }}
//                         >
//                             {t("Cancel")}
//                         </Button>
//                         <Button
//                             className="checkout-page-ok-btn"
//                             onClick={currentStep === 0 ? handleNext : handleConfirmOrder}
//                         >
//                             {currentStep === 0 ?
//                                 t("Continue") :
//                                 t("Place order")
//                             }
//                         </Button>
//                     </div>
//                 </Col>
//                 <Col lg={5} md={5} className="checkout-products-before-map-div">
//                     <Row className="checkout-col2 row-padding-remover">
//                         {cartProducts?.order_items?.map((val, i) =>
//                             <Col md={12} key={i} className="cursor-pointer checkout-product-list-main-div-col"
//                                 onClick={() => handleViewProductDetails(val?.product_id)}>

//                                 <div className="checkout-products-list-col">
//                                     <img
//                                         src={val?.image_url}
//                                         className="checkout-product-img"
//                                         alt="..."
//                                     />

//                                     <div>
//                                         <div className="checkout-product-name mb-2">{val?.name}</div>
//                                         <div className="checkout-product-qty mb-2">{t("Qty")}: {val?.quantity}</div>
//                                         <div className="product_page_price_main_div_cls">
//                                             {(val?.discount > 0) ?
//                                                 <>
//                                                     <span className="checkout-view-product-amount-del-tag me-1"><MdOutlineCurrencyRupee /><del>{parseFloat(val?.rate)}</del></span>
//                                                     <span className="checkout-product-final-price ms-1"> <MdOutlineCurrencyRupee />{parseFloat(val?.rate) - parseFloat(val?.discount)}/-</span>
//                                                 </> :
//                                                 <span className="checkout-product-final-price"><MdOutlineCurrencyRupee />{parseFloat(val?.rate)}/-</span>
//                                             }
//                                         </div>
//                                     </div>
//                                 </div>
//                             </Col>
//                         )}
//                     </Row>
//                 </Col>
//             </Row>
//         </Container>
//     )
// }

import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import cartActions from "../../Redux/cart/actions";
import userActions from "../../Redux/user/actions";
import commonActions from "../../Redux/common/actions";
import couponActions from "../../Redux/coupon/actions";
import checkoutActions from "../../Redux/checkout/actions";
import homeActions from "../../Redux/Home/action";
import { FaCirclePlus } from "react-icons/fa6";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { DecryptFunction, EncryptFunction } from "../../utils/cryptoFunction";
import { priceRoundOff } from "../../utils/validation";
import StepperComponent from "./stepper";
import moment from "moment-timezone";
import { LogEvent } from "../../utils/firebase";

// import SellerSelectAddressModal from "../SellerProfile/SellerSelectAddressModal";
import DefaultAddressChangeModal from "../modal/defaultaddresschangeModal";

export default function CheckoutIndex() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const { cartProducts } = useSelector((state) => state.cartReducer);
  const { userAddress } = useSelector((state) => state.userReducer);

  const { available_Coupons } = useSelector((state) => state.couponReducer);

  const [selleraddress, setSelleraddress] = useState();

  const { deliveryType, checkoutValues, paymentType } = useSelector(
    (state) => state.checkoutReducer
  );
  // console.log("1234",deliveryType)
  // console.log("checkoutValues", checkoutValues)

  const [showAddressModal, setShowAddressModal] = useState(false);

  const [codAgreeValue, setCodAgreeValue] = useState({
    value1: false,
    value2: false,
  });
  const [checkoutErr, setCheckoutErr] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [priceDetails, setPriceDetails] = useState({
    amount: 0,
    discount: 0,
    coupon_discount: 0,
    total: 0,
  });

  const handleAddress = (Address) => {
    Address[`default_address`] = 1;
    setSelleraddress(Address);
    const id = DecryptFunction(localStorage.getItem("auth_user"));
    Address["user_id"] = id;
    // dispatch({
    //     type: commonActions.GET_ADDRESS_COORDINATES,
    //     payload: Address,
    // });
    dispatch({
      type: userActions.UPDATE_USER_ADDRESS,
      payload: { id: Address?.id, data: Address },
    });

    setShowAddressModal(false);
  };

  let [copiedCoupon, setCopiedCoupon] = useState({
    code: null,
    index: null,
  });

  useEffect(() => {
    if (id) {
      dispatch({
        type: cartActions.GET_CART_PRODUCT_LIST,
        payload: { id: id },
      });
    } else if (
      localStorage.getItem("token") &&
      localStorage.getItem("auth_user")
    ) {
      navigate(`/checkout?id=${localStorage.getItem("auth_user")}`);
      dispatch({
        type: cartActions.GET_CART_PRODUCT_LIST,
        payload: { id: localStorage.getItem("auth_user") },
      });
    }

    //eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    dispatch({ type: checkoutActions.GET_DELIVERY_TYPE_LIST });
    dispatch({ type: homeActions.GET_HOME_CONTENTS });

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (localStorage.getItem("auth_user")) {
      const id = DecryptFunction(localStorage.getItem("auth_user"));
      if (id) {
        dispatch({ type: userActions.GET_USER_ADDRESS, payload: { id: id } });
        dispatch({
          type: homeActions.GET_USER_PROFILE,
          payload: { id: id },
        });
      }
      dispatch({
        type: couponActions.GET_COUPON_BY_MOBILENUMBER,
        payload: localStorage.getItem("auth_user_no"),
      });
    }

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    // console.log(checkoutValues, 'checkoutValues')
    // console.log('checkout values check', checkoutValues)
    if (
      cartProducts &&
      cartProducts?.order_items?.length > 0 &&
      userAddress?.length > 0 &&
      deliveryType?.length > 0
    ) {
      let array = { amount: 0, discount: 0, total: 0 };
      //eslint-disable-next-line
      cartProducts?.order_items?.map((val) => {
        let product_rate = parseFloat(val?.rate) * parseInt(val?.quantity);
        array["amount"] =
          parseFloat(array["amount"]) + parseFloat(product_rate);
        let product_dis = parseFloat(val?.discount) * parseInt(val?.quantity);
        array["discount"] =
          parseFloat(array["discount"]) + parseFloat(product_dis);
      });
      array["total"] = array["amount"] - array["discount"];
      setPriceDetails(array);

      dispatch({ type: checkoutActions.CALCUATE_GST });
    }

    //eslint-disable-next-line
  }, [cartProducts, userAddress, deliveryType]);

  const handleAddAddress = () => {
    LogEvent("Checkout_DeliveryAddress_Add_Clicked");
    dispatch({
      type: commonActions.SET_SHOW_USER_ADDRESS_MODAL,
      payload: true,
    });
  };

  const handleShowAddressModal = () => {
    setShowAddressModal(true);
  };

  const handleNext = () => {
    LogEvent("Checkout_Step1_Continue_Clicked");
    window.scrollTo(0, 0);
    setCurrentStep((prevStep) => prevStep + 1);
  };

  // const handleChangeValues = (e, type) => {
  //     if (type === 'deliveryAddress' && e.target.value) {
  //         LogEvent('Checkout_DeliveryAddress_Changed')
  //         dispatch({
  //             type: checkoutActions.CHECKOUT_VALUES,
  //             payload: { ...checkoutValues, DeliveryAddress: JSON.parse(e.target.value) }
  //         });
  //         dispatch({
  //             type: checkoutActions.CALCULATE_SHIPPING, payload: {
  //                 "weight_kg": checkoutValues?.wight_in_kg,
  //                 "state": JSON.parse(e.target.value)?.state,
  //                 "provider_id": checkoutValues?.DeliveryType?.id,
  //                 "order_value": checkoutValues?.total,
  //                 "shipping_type": checkoutValues?.DeliveryType?.shipping_type
  //             }
  //         });
  //     } else if (type === 'deliveryType' && e.target.value) {
  //         LogEvent('Checkout_DeliveryType_Changed')
  //         dispatch({
  //             type: checkoutActions.CHECKOUT_VALUES,
  //             payload: { ...checkoutValues, DeliveryType: JSON.parse(e.target.value) }
  //         });
  //         dispatch({
  //             type: checkoutActions.CALCULATE_SHIPPING, payload: {
  //                 "weight_kg": checkoutValues?.wight_in_kg,
  //                 "state": checkoutValues?.DeliveryAddress?.state,
  //                 "provider_id": JSON.parse(e.target.value)?.id,
  //                 "order_value": checkoutValues?.total,
  //                 "shipping_type": checkoutValues?.DeliveryType?.shipping_type

  //             }
  //         });
  //     }
  // };
  useEffect(() => {
    !checkoutValues.shippingCharge &&
      dispatch({
        type: checkoutActions.CALCULATE_SHIPPING,
        payload: {
          weight_kg: checkoutValues?.wight_in_kg,
          state: checkoutValues?.DeliveryAddress?.state,
          provider_id: checkoutValues?.DeliveryType?.id,
          order_value: checkoutValues?.total,
          shipping_type: checkoutValues?.DeliveryType?.shipping_type,
        },
      });
  }, [checkoutValues]);
  const handleChangeValues = (e, type) => {
    if (type === "deliveryAddress" && e.target.value) {
      LogEvent("Checkout_DeliveryAddress_Changed");
      dispatch({
        type: checkoutActions.CHECKOUT_VALUES,
        payload: {
          ...checkoutValues,
          DeliveryAddress: JSON.parse(e.target.value),
        },
      });
      dispatch({
        type: checkoutActions.CALCULATE_SHIPPING,
        payload: {
          weight_kg: checkoutValues?.wight_in_kg,
          state: JSON.parse(e.target.value)?.state,
          provider_id: checkoutValues?.DeliveryType?.id,
          order_value: checkoutValues?.total,
          shipping_type: checkoutValues?.DeliveryType?.shipping_type,
        },
      });
      // window.location.reload();  // Reload the page if needed
    } else if (type === "deliveryType" && e.target.value) {
      LogEvent("Checkout_DeliveryType_Changed");
      dispatch({
        type: checkoutActions.CHECKOUT_VALUES,
        payload: {
          ...checkoutValues,
          DeliveryType: JSON.parse(e.target.value),
        },
      });
      dispatch({
        type: checkoutActions.CALCULATE_SHIPPING,
        payload: {
          weight_kg: checkoutValues?.wight_in_kg,
          state: checkoutValues?.DeliveryAddress?.state,
          provider_id: JSON.parse(e.target.value)?.id,
          order_value: checkoutValues?.total,
          shipping_type: JSON.parse(e.target.value)?.shipping_type, // Corrected this line
        },
      });
      // window.location.reload();  // Reload the page if needed
    }
  };

  const handlePaymentChange = (value) => {
    LogEvent("Checkout_PaymentType_Changed");
    dispatch({
      type: checkoutActions.CHECKOUT_VALUES,
      payload: { ...checkoutValues, PaymentType: value },
    });
    setCheckoutErr("");
    setCodAgreeValue({ value1: false, value2: false });
    if (value === 0) {
      dispatch({
        type: checkoutActions.CALCULATE_SHIPPING,
        payload: {
          weight_kg: checkoutValues?.wight_in_kg,
          state: checkoutValues?.DeliveryAddress?.state,
          provider_id: checkoutValues?.DeliveryType?.id,
          order_value: checkoutValues?.total,
          shipping_type: "COD",

          // "shipping_type": checkoutValues?.DeliveryType?.shipping_type
        },
      });
    } else {
      dispatch({
        type: checkoutActions.CALCULATE_SHIPPING,
        payload: {
          weight_kg: checkoutValues?.wight_in_kg,
          state: checkoutValues?.DeliveryAddress?.state,
          provider_id: checkoutValues?.DeliveryType?.id,
          order_value: checkoutValues?.total,
          shipping_type: checkoutValues?.DeliveryType?.shipping_type,
        },
      });
    }
  };

  const handleCouponApply = (code, index) => {
    LogEvent("Checkout_Coupon_Applied");
    setCopiedCoupon(
      (copiedCoupon = { code: code, index: index, applied_Coupon: code })
    );
    let couponValues = available_Coupons?.find(
      (val) => val?.code === copiedCoupon?.code
    );
    let pricedetails = { ...priceDetails };
    if (couponValues) {
      let copied = { ...copiedCoupon };
      copied["Error"] = "";
      let coupon_discount = 0,
        finalTotal;
      if (couponValues?.discount_type === 1) {
        coupon_discount = priceRoundOff(
          ((parseFloat(priceDetails?.amount) -
            parseFloat(priceDetails?.discount)) *
            parseFloat(couponValues?.discount)) /
            100
        );
      } else {
        coupon_discount = priceRoundOff(couponValues?.discount);
      }
      finalTotal = priceRoundOff(
        parseFloat(pricedetails?.total) - parseFloat(coupon_discount)
      );
      pricedetails["coupon_discount"] = coupon_discount;
      pricedetails["total"] = finalTotal;
      setPriceDetails(pricedetails);
      dispatch({
        type: checkoutActions.CHECKOUT_VALUES,
        payload: {
          ...checkoutValues,
          coupon_discount: coupon_discount,
          applied_Coupon: couponValues,
        },
      });
      dispatch({ type: checkoutActions.CALCUATE_GST });
    } else {
      let copied = { ...copiedCoupon };
      copied["Error"] = "Invalid Coupon code";
      setCopiedCoupon(copied);
    }
  };

  const handledeliveryNotes = (e) => {
    dispatch({
      type: checkoutActions.CHECKOUT_VALUES,
      payload: { ...checkoutValues, DeliveryNotes: e.target.value },
    });
  };

  // useEffect(() => {
  //     if (!checkoutValues) {
  //         window.location.reload()
  //     }
  // }, [checkoutValues])

  // useEffect(() => {
  //     if(checkoutValues){
  //         dispatch({
  //             type: checkoutActions.CALCULATE_SHIPPING, payload: {
  //                 "weight_kg": checkoutValues?.wight_in_kg,
  //                 // "state": JSON.parse(e.target.value)?.state,
  //                 "provider_id": checkoutValues?.DeliveryType?.id,
  //                 "order_value": checkoutValues?.total,
  //                 "shipping_type": checkoutValues?.DeliveryType?.shipping_type || true
  //             }
  //         });
  //     }

  // },[checkoutValues])

  const handleConfirmOrder = (e) => {
    e.preventDefault();
    LogEvent("Checkout_PlaceOrder_Clicked");
    if (
      checkoutValues?.PaymentType === 0 &&
      (codAgreeValue?.value1 === false || codAgreeValue?.value2 === false)
    ) {
      setCheckoutErr("Please agree the terms");
    } else {
      setCheckoutErr("");
      let userid = DecryptFunction(localStorage.getItem("auth_user"));
      if (userid) {
        dispatch({
          type: checkoutActions.CREATE_NEW_ORDER,
          payload: {
            data: {
              id: cartProducts?.id,
              user_id: userid,
              subtotal: parseFloat(checkoutValues?.product_amount),
              // "subtotal": parseFloat(checkoutValues?.total) - parseFloat(checkoutValues?.gst_amount),
              vat: 0,
              shipping_charges: parseFloat(checkoutValues?.shippingCharge || 0),
              total_amount: checkoutValues?.total,
              location_id: cartProducts?.location_id,
              discount: checkoutValues?.discount,
              coupon_discount: checkoutValues?.coupon_discount,
              notes: checkoutValues?.DeliveryNotes,
              order_date: moment().format("YYYY-MM-DD"),
              payment_type: checkoutValues?.PaymentType,
              transporter_id: checkoutValues?.DeliveryType?.id,
              order_type: 0,
              order_source: 2,
            },
            navigate: navigate,
          },
        });
      }
    }
  };

  const handleViewProductDetails = (id) => {
    LogEvent("Checkout_Product_Clicked");
    const encrypt = EncryptFunction(id);
    if (encrypt) {
      navigate(`/product-details?id=${encrypt}`);
      // window.location.reload();
    }
  };

  // if (checkoutValues.shippingCharge === undefined) {
  //     dispatch({ type: commonActions.SET_BUTTON_LOADER, payload: true });

  // } else {
  //     dispatch({ type: commonActions.SET_BUTTON_LOADER, payload: false });
  // }

  return (
    <>
      {checkoutValues.shippingCharge === undefined ? (
        <div
          style={{
            width: "90vw",
            height: "100vh",
            textAlign: "center",
            alignContent: "center",
          }}
        >
          <h1>Loading...</h1>
        </div>
      ) : (
        <Container className="mb-5">
          {checkoutValues && (
            <Row className="row-padding-remover">
              <Col md={12} className="my-4">
                <StepperComponent currentStep={currentStep} />
              </Col>
              <Col lg={7} md={7}>
                <div className="checkout-col1">
                  {currentStep === 0 && (
                    <Row className="mb-2 row-padding-remover">
                      <Col md={12} sm={12} className="mb-2">
                        <label className="form-label checkout-step1-label">
                          {t("Delivery Type")}
                        </label>
                      </Col>
                      <Col md={12}>
                        {localStorage.getItem("cattle_type")?.toLowerCase() ===
                        "feed"
                          ? deliveryType
                              ?.filter(
                                (val) =>
                                  val?.shipping_type?.toLowerCase() ===
                                  "hyperlocal delivery"
                              )
                              ?.map((val, i) => (
                                <div
                                  className="d-flex align-items-start mb-3"
                                  key={i}
                                >
                                  <input
                                    type="radio"
                                    id="deliverytype"
                                    name="Deliery_Type"
                                    className="mt-1 checkout-radio-btn"
                                    value={JSON.stringify(val)}
                                    defaultChecked={
                                      !checkoutValues?.DeliveryType
                                        ? true
                                        : checkoutValues?.DeliveryType &&
                                          JSON.stringify(
                                            checkoutValues?.DeliveryType
                                          ) === JSON.stringify(val)
                                        ? true
                                        : false
                                    }
                                    onChange={(e) =>
                                      handleChangeValues(e, "deliveryType")
                                    }
                                  />
                                  <label
                                    htmlFor="deliverytype"
                                    className={`ps-3 checkout-delivery-type-label-values ${
                                      (checkoutValues?.DeliveryType &&
                                        JSON.stringify(
                                          checkoutValues?.DeliveryType
                                        ) === JSON.stringify(val)) ||
                                      (!checkoutValues?.DeliveryType &&
                                        val?.shipping_type === "Hyperlocal")
                                        ? "elected-delivery-type-label-active"
                                        : ""
                                    }`}
                                  >
                                    {val?.shipping_type} [{val?.delivery_range}]
                                  </label>
                                </div>
                              ))
                          : deliveryType
                              ?.filter(
                                (val) =>
                                  val?.shipping_type === "Express" ||
                                  val?.shipping_type === "Standard"
                              )
                              ?.map((val, i) => (
                                <div
                                  className="d-flex align-items-start mb-3"
                                  key={i}
                                >
                                  <input
                                    type="radio"
                                    id="deliverytype"
                                    name="Deliery_Type"
                                    className="mt-1 checkout-radio-btn"
                                    value={JSON.stringify(val)}
                                    defaultChecked={
                                      (checkoutValues?.DeliveryType &&
                                        JSON.stringify(
                                          checkoutValues?.DeliveryType
                                        ) === JSON.stringify(val)) ||
                                      (!checkoutValues?.DeliveryType &&
                                        val?.shipping_type === "Express")
                                        ? true
                                        : false
                                    }
                                    onChange={(e) =>
                                      handleChangeValues(e, "deliveryType")
                                    }
                                  />
                                  <label
                                    htmlFor="deliverytype"
                                    className={`ps-3 checkout-delivery-type-label-values ${
                                      (checkoutValues?.DeliveryType &&
                                        JSON.stringify(
                                          checkoutValues?.DeliveryType
                                        ) === JSON.stringify(val)) ||
                                      (!checkoutValues?.DeliveryType &&
                                        val?.shipping_type === "Express")
                                        ? "elected-delivery-type-label-active"
                                        : ""
                                    }`}
                                  >
                                    {val?.shipping_type} [{val?.delivery_range}]
                                  </label>
                                </div>
                              ))}
                        <div className="checkout-page-hr-cls" />
                      </Col>

                      <Col md={9} sm={12}>
                        <label className="form-label checkout-step1-label">
                          {t("Delivery Address")}
                        </label>
                      </Col>
                      <Col
                        md={3}
                        sm={12}
                        className="d-flex justify-content-end"
                      >
                        <Col
                          md={3}
                          sm={12}
                          className="d-flex justify-content-end"
                        >
                          <div
                            className="change-address-link d-flex justify-content-end"
                            onClick={handleShowAddressModal}
                          >
                            {selleraddress
                              ? t("Change Address ")
                              : t("Change Address ")}
                          </div>
                        </Col>
                      </Col>

                      <Col md={12}>
                        {userAddress
                          .filter((val) => val?.default_address === 1) // Filter addresses where default_address is 1
                          .map((val, i) => (
                            <div
                              className="d-flex align-items-start mb-3"
                              key={i}
                            >
                              <input
                                type="radio"
                                id={`address${i}`} // Use a unique id for each input
                                name="User_Address"
                                className="mt-1 checkout-radio-btn"
                                value={JSON.stringify(val)}
                                defaultChecked={
                                  (checkoutValues?.DeliveryAddress &&
                                    JSON.stringify(
                                      checkoutValues?.DeliveryAddress
                                    ) === JSON.stringify(val)) ||
                                  (!checkoutValues?.DeliveryAddress && i === 0)
                                    ? true
                                    : false
                                }
                                onChange={(e) =>
                                  handleChangeValues(e, "deliveryAddress")
                                }
                              />
                              <label
                                htmlFor={`address${i}`} // Match the input's id
                                className="ps-3 checkout-delivery-address-font"
                              >
                                {`${val?.address_line1}, ${val?.address_line2}, ${val?.address_line3},`}{" "}
                                <br />
                                {`${val?.city}- ${val?.pincode}`} <br />
                                {`${val?.state}, ${val?.country}`}
                              </label>
                              <br />
                            </div>
                          ))}

                        <div className="checkout-page-hr-cls" />
                        <DefaultAddressChangeModal
                          showModal={showAddressModal}
                          closeModal={() => setShowAddressModal(false)}
                          handleAddress={handleAddress}
                        />
                      </Col>
                      <Col md={12}>
                        <label className="form-label checkout-step1-label">
                          {t("My Coupons")}
                        </label>
                        {available_Coupons?.length > 0 ? (
                          <div className="checkout-coupon-code-maind-cls">
                            {available_Coupons?.map((val, i) => (
                              <div className="discount-code" key={i}>
                                <div className="offer-container">
                                  <span className="offer-text">
                                    {val?.discount_type === 1
                                      ? `${val?.discount}% offer on this order`
                                      : `₹${val?.discount} offer on this order`}
                                  </span>
                                  <div className="code-container">
                                    <span className="code-text">
                                      CODE : {val?.code}
                                    </span>
                                    {i !== copiedCoupon?.index ? (
                                      <div
                                        className="apply-button"
                                        onClick={() =>
                                          handleCouponApply(val?.code, i, val)
                                        }
                                      >
                                        {t("TAP TO APPLY")}
                                      </div>
                                    ) : (
                                      <div className="applied-text">
                                        {t("APPLIED")}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="text-center mt-3">
                            <h6>{t("No Coupons")}</h6>
                          </div>
                        )}
                        <small className="text-danger fw-600 ms-1">
                          {copiedCoupon?.Error}
                        </small>
                        <div className="checkout-page-hr-cls" />
                      </Col>
                      {!(checkoutValues.shippingCharge === undefined) && (
                        <Col md={12}>
                          <label className="form-label checkout-step1-label mb-3">
                            {t("Price Details")}
                          </label>
                          <dl className="d-flex justify-content-between my-2">
                            <dt className="checkout-page-price-details-label-1">
                              {t("Subtotal")}
                            </dt>
                            <dd className="cart-product-subtotal">
                              <MdOutlineCurrencyRupee />{" "}
                              {parseFloat(
                                checkoutValues?.product_amount ||
                                  priceDetails?.amount
                              )?.toFixed(2)}
                            </dd>
                          </dl>
                          <dl className="d-flex justify-content-between my-2">
                            <dt className="checkout-page-price-details-label-1">
                              {t("Discount")}
                            </dt>
                            <dd className="cart-product-dis-dd">
                              - <MdOutlineCurrencyRupee />{" "}
                              {parseFloat(
                                checkoutValues?.discount ||
                                  priceDetails?.discount
                              )?.toFixed(2)}
                            </dd>
                          </dl>

                          {checkoutValues &&
                            priceDetails &&
                            (checkoutValues?.coupon_discount ||
                              priceDetails?.coupon_discount) && (
                              <dl className="d-flex justify-content-between my-2">
                                <dt className="checkout-page-price-details-label-1">
                                  {t("Coupon Discount")}
                                </dt>
                                <dd className="cart-product-dis-dd">
                                  - <MdOutlineCurrencyRupee />{" "}
                                  {parseFloat(
                                    checkoutValues?.coupon_discount ||
                                      priceDetails?.coupon_discount
                                  )?.toFixed(2)}
                                </dd>
                              </dl>
                            )}

                          {!(checkoutValues.shippingCharge === undefined) && (
                            <dl className="d-flex justify-content-between my-2">
                              <dt className="checkout-page-price-details-label-1">
                                {t("Shipping Charges")}
                              </dt>
                              <dd className="cart-product-subtotal">
                                <MdOutlineCurrencyRupee />{" "}
                                {parseFloat(
                                  checkoutValues?.shippingCharge
                                )?.toFixed(2)}
                              </dd>
                            </dl>
                          )}
                          <div className="checkout-page-hr-cls" />
                          <dl className="d-flex justify-content-between">
                            <dt>{t("Total price")}</dt>
                            <dd className="cart-product-final-price-dd">
                              <strong>
                                <MdOutlineCurrencyRupee />{" "}
                                {parseFloat(
                                  checkoutValues?.total || priceDetails?.total
                                )?.toFixed(2)}
                              </strong>
                            </dd>
                          </dl>
                        </Col>
                      )}
                    </Row>
                  )}
                  {/* <Col md={12}>
    {localStorage.getItem('cattle_type').toLowerCase() === 'feed' ? (
        deliveryType?.filter((val) => val?.shipping_type.toLowerCase() === 'hyperlocal delivery')?.map((val, i) => (
            <div className="d-flex align-items-start mb-3" key={i}>
                <input
                    type="radio"
                    id={`deliverytype-${i}`}
                    name="Delivery_Type"
                    className="mt-1 checkout-radio-btn"
                    value={JSON.stringify(val)}
                    defaultChecked={(!checkoutValues?.DeliveryType) ? true : ((checkoutValues?.DeliveryType && JSON.stringify(checkoutValues?.DeliveryType) === JSON.stringify(val)) ? true : false)}
                    onChange={(e) => handleChangeValues(e, 'deliveryType')}
                />
                <label
                    htmlFor={`deliverytype-${i}`}
                    className={`ps-3 checkout-delivery-type-label-values ${((checkoutValues?.DeliveryType && JSON.stringify(checkoutValues?.DeliveryType) === JSON.stringify(val)) || (!checkoutValues?.DeliveryType && val?.shipping_type === 'Hyperlocal')) ? 'elected-delivery-type-label-active' : ''}`}
                >
                    {val?.shipping_type} [{val?.delivery_range}]
                </label>
            </div>
        ))
    ) : (
        deliveryType?.filter((val) => val?.shipping_type === 'Express' || val?.shipping_type === 'Standard')?.map((val, i) => (
            <div className="d-flex align-items-start mb-3" key={i}>
                <input
                    type="radio"
                    id={`deliverytype-${i}`}
                    name="Delivery_Type"
                    className="mt-1 checkout-radio-btn"
                    value={JSON.stringify(val)}
                    defaultChecked={((checkoutValues?.DeliveryType && JSON.stringify(checkoutValues?.DeliveryType) === JSON.stringify(val)) || (!checkoutValues?.DeliveryType && val?.shipping_type === 'Express')) ? true : false}
                    onChange={(e) => handleChangeValues(e, 'deliveryType')}
                />
                <label
                    htmlFor={`deliverytype-${i}`}
                    className={`ps-3 checkout-delivery-type-label-values ${((checkoutValues?.DeliveryType && JSON.stringify(checkoutValues?.DeliveryType) === JSON.stringify(val)) || (!checkoutValues?.DeliveryType && val?.shipping_type === 'Express')) ? 'elected-delivery-type-label-active' : ''}`}
                >
                    {val?.shipping_type} [{val?.delivery_range}]
                </label>
            </div>
        ))
    )}
    <div className="checkout-page-hr-cls" />
</Col> */}
                  {/* <Col md={12}>
    {(() => {
        // Filter delivery types
        const hyperLocalTypes = deliveryType?.filter(val => val?.shipping_type === 'Hyperlocal Delivery');
        const otherTypes = deliveryType?.filter(val => val?.shipping_type === 'Express' || val?.shipping_type === 'Standard');

        // Determine which types to display
        const typesToDisplay = hyperLocalTypes?.length > 0 ? hyperLocalTypes : otherTypes;

        return typesToDisplay?.map((val, i) => (
            <div className="d-flex align-items-start mb-3" key={i}>
                <input
                    type="radio"
                    id={`deliverytype-${i}`}
                    name="Delivery_Type"
                    className="mt-1 checkout-radio-btn"
                    value={JSON.stringify(val)}
                    defaultChecked={hyperLocalTypes.length > 0 && i === 0} 
                    onChange={(e) => handleChangeValues(e, 'deliveryType')}
                />
                <label
                    htmlFor={`deliverytype-${i}`}
                    className={`ps-3 checkout-delivery-type-label-values ${((checkoutValues?.DeliveryType && JSON.stringify(checkoutValues?.DeliveryType) === JSON.stringify(val))) ? 'selected-delivery-type-label-active' : ''}`}
                >
                    {val?.shipping_type} [{val?.delivery_range}]
                </label>
            </div>
        ));
    })()}
    <div className="checkout-page-hr-cls" />
</Col> */}

                  {currentStep === 1 && (
                    <Row className="row-padding-remover">
                      <Col md={12}>
                        <label className="form-label checkout-step1-label mb-3">
                          {t("Price Details")}
                        </label>
                        <dl className="d-flex justify-content-between my-2">
                          <dt className="checkout-page-price-details-label-1">
                            {t("Subtotal")}
                          </dt>
                          <dd className="cart-product-subtotal">
                            <MdOutlineCurrencyRupee />{" "}
                            {parseFloat(
                              checkoutValues?.product_amount ||
                                priceDetails?.amount
                            )?.toFixed(2)}
                          </dd>
                        </dl>
                        <dl className="d-flex justify-content-between my-2">
                          <dt className="checkout-page-price-details-label-1">
                            {t("Discount")}
                          </dt>
                          <dd className="cart-product-dis-dd">
                            - <MdOutlineCurrencyRupee />{" "}
                            {parseFloat(
                              checkoutValues?.discount || priceDetails?.discount
                            )?.toFixed(2)}
                          </dd>
                        </dl>
                        {(checkoutValues?.coupon_discount ||
                          priceDetails?.coupon_discount) && (
                          <dl className="d-flex justify-content-between my-2">
                            <dt className="checkout-page-price-details-label-1">
                              {t("Coupon Discount")}
                            </dt>
                            <dd className="cart-product-dis-dd">
                              - <MdOutlineCurrencyRupee />{" "}
                              {parseFloat(
                                checkoutValues?.coupon_discount ||
                                  priceDetails?.coupon_discount
                              )?.toFixed(2)}
                            </dd>
                          </dl>
                        )}
                        {checkoutValues && (
                          <dl className="d-flex justify-content-between my-2">
                            <dt className="checkout-page-price-details-label-1">
                              {t("Shipping Charges")}
                            </dt>
                            <dd className="cart-product-subtotal">
                              <MdOutlineCurrencyRupee />{" "}
                              {parseFloat(
                                checkoutValues?.shippingCharge ||
                                  priceDetails?.shippingCharge
                              )?.toFixed(2)}
                            </dd>
                          </dl>
                        )}
                        <div className="checkout-page-hr-cls" />
                        <dl className="d-flex justify-content-between mt-3 mb-1">
                          <dt>{t("Total price")}</dt>
                          <dd className="cart-product-final-price-dd">
                            <strong>
                              <MdOutlineCurrencyRupee />{" "}
                              {parseFloat(
                                checkoutValues?.total || priceDetails?.total
                              )?.toFixed(2)}
                            </strong>
                          </dd>
                        </dl>
                        <div className="checkout-page-hr-cls" />
                      </Col>
                      {/* <Col lg={12} className="mt-1">
                                    <label className="form-label checkout-step1-label">{t("Payment")}</label>
                                    {paymentType?.filter((val) => {
                                        const total = parseInt(checkoutValues?.total);
                                        if (total < 500 || total > 1500) {
                                            return val?.title !== 'COD';
                                        } else {
                                            return true;
                                        }
                                    })?.map((val, i) =>
                                        <div className="d-flex align-items-start mb-2 checkout-payment-type" key={i}>
                                            <input
                                                type="radio"
                                                id="paymenttype"
                                                name="Payment_Type"
                                                className="mt-1 checkout-radio-btn"
                                                value={val.title === 'COD' ? 0 : 1}
                                                defaultChecked={(checkoutValues?.PaymentType === 1 && val.title !== 'COD') ? true : false}
                                                onChange={() => handlePaymentChange(val.title === 'COD' ? 0 : 1)}
                                            />
                                            <label
                                                htmlFor="paymenttype"
                                                className="ps-3 checkout-delivery-address-font"
                                            >
                                                <img src={val?.img_url} alt="" className="checkout-payment-type-img" />{val?.title}
                                            </label>
                                        </div>
                                    )}
                                    <div className="checkout-page-hr-cls my-3" />
                                </Col> */}
                      <Col lg={12} className="mt-1">
                        <label className="form-label checkout-step1-label">
                          {t("Payment")}
                        </label>

                        {paymentType
                          ?.filter((val) => {
                            const total = parseInt(checkoutValues?.total);
                            const cattleType = localStorage
                              .getItem("cattle_type")
                              ?.toLowerCase();

                            // Do not show COD if cattle_type is 'feed'
                            if (cattleType === "feed") {
                              return val?.title !== "COD";
                            }

                            // For other cattle types, apply the total amount condition for COD
                            if (total < 500 || total > 1500) {
                              return val?.title !== "COD";
                            } else {
                              return true;
                            }
                          })
                          ?.reverse()
                          ?.map((val, i) => (
                            <div
                              className="d-flex align-items-start mb-2 checkout-payment-type"
                              key={i}
                            >
                              <input
                                type="radio"
                                id={`paymenttype-${i}`}
                                name="Payment_Type"
                                className="mt-1 checkout-radio-btn"
                                value={val?.title === "COD" ? 0 : 1}
                                // value={val.title === 1}
                                // defaultChecked={false}
                                defaultChecked={
                                  checkoutValues?.PaymentType === 1 &&
                                  val?.title !== "COD"
                                    ? true
                                    : false
                                }
                                onChange={() =>
                                  handlePaymentChange(
                                    val?.title === "COD" ? 0 : 1
                                  )
                                }
                                // onChange={() => handlePaymentChange(val.title === 1)}
                              />

                              <label
                                htmlFor={`paymenttype-${i}`}
                                className="ps-3 checkout-delivery-address-font"
                              >
                                <img
                                  src={val?.img_url}
                                  alt=""
                                  className="checkout-payment-type-img"
                                />
                                {val?.title}
                              </label>
                            </div>
                          ))}
                        <div className="checkout-page-hr-cls my-3" />
                      </Col>

                      <Col md={12}>
                        <label className="form-label checkout-step1-label">
                          {t("Delivery Notes")}
                        </label>
                        <textarea
                          className="form-control"
                          onChange={handledeliveryNotes}
                        />
                      </Col>
                      {checkoutValues?.PaymentType === 0 && (
                        <Col md={12} className="mt-4">
                          <div className="d-flex">
                            <input
                              type="checkbox"
                              className="me-3 checkout-iagree-input"
                              onClick={(e) =>
                                setCodAgreeValue({
                                  ...codAgreeValue,
                                  value1: e.target.checked,
                                })
                              }
                              value={codAgreeValue?.value1}
                            />
                            <div className="checkout-policy-checkbox-div">
                              {t(
                                "I agree that i will be billed the amount of shipping charges in the next order, If the order is rejected at the time of delivery"
                              )}
                            </div>
                          </div>
                          <div className="d-flex mt-2">
                            <input
                              type="checkbox"
                              className="me-3 checkout-iagree-input"
                              onClick={(e) =>
                                setCodAgreeValue({
                                  ...codAgreeValue,
                                  value2: e.target.checked,
                                })
                              }
                              value={codAgreeValue?.value2}
                            />
                            <div className="checkout-policy-checkbox-div">
                              {t(
                                "I agree that I will pay the cash on delivery and I will not reject the products for reasons other than the damage in packing"
                              )}
                            </div>
                          </div>
                        </Col>
                      )}
                      {checkoutErr &&
                        (!codAgreeValue?.value1 || !codAgreeValue?.value2) && (
                          <p className="text-danger my-2">{checkoutErr}</p>
                        )}
                    </Row>
                  )}
                </div>
                <div className="checkout-page-btns-div">
                  <Button
                    className="checkout-page-cancel-btn"
                    onClick={() => {
                      LogEvent("Checkout_Cancel_Clicked");
                      navigate("/cart");
                    }}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button
                    className="checkout-page-ok-btn"
                    onClick={
                      currentStep === 0 ? handleNext : handleConfirmOrder
                    }
                  >
                    {currentStep === 0 ? t("Continue") : t("Place order")}
                  </Button>
                </div>
              </Col>
              <Col lg={5} md={5} className="checkout-products-before-map-div">
                <Row className="checkout-col2 row-padding-remover">
                  {cartProducts?.order_items?.map((val, i) => (
                    <Col
                      md={12}
                      key={i}
                      className="cursor-pointer checkout-product-list-main-div-col"
                      onClick={() => handleViewProductDetails(val?.product_id)}
                    >
                      <div className="checkout-products-list-col">
                        <img
                          src={val?.image_url}
                          className="checkout-product-img"
                          alt="..."
                        />

                        <div>
                          <div className="checkout-product-name mb-2">
                            {val?.name}
                          </div>
                          <div className="checkout-product-qty mb-2">
                            {t("Qty")}: {val?.quantity}
                          </div>
                          <div className="product_page_price_main_div_cls">
                            {val?.discount > 0 ? (
                              <>
                                <span className="checkout-view-product-amount-del-tag me-1">
                                  <MdOutlineCurrencyRupee />
                                  <del>{parseFloat(val?.rate)}</del>
                                </span>
                                <span className="checkout-product-final-price ms-1">
                                  {" "}
                                  <MdOutlineCurrencyRupee />
                                  {parseFloat(val?.rate) -
                                    parseFloat(val?.discount)}
                                  /-
                                </span>
                              </>
                            ) : (
                              <span className="checkout-product-final-price">
                                <MdOutlineCurrencyRupee />
                                {parseFloat(val?.rate)}/-
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          )}
        </Container>
      )}
    </>
  );
}
